/* eslint-disable no-console */
import $ from 'jquery';

let $FilterBtn = $('.js-filter-btn');
let $DownloadBtn = $('.js-download-btn');
let $Download = $('.js-download-dropdown');
let $FilterLocationDevBtn = $('.js-location-filter-btn');
let $FilterLocationDev = $('.js-location-filter-dropdown');
let $SelectedDropdown = $(".js-filter-selected-dropdown");
const active = 'is-active';
let isActive = false;
let performInstantly = false;

export function getIsActive() {
  return isActive;
}

export function refreshVariablesAndRebindEvents(callback) {
  $FilterBtn = $('.js-filter-btn');
  $DownloadBtn = $('.js-download-btn');
  $Download = $('.js-download-dropdown');
  $FilterLocationDevBtn = $('.js-location-filter-btn');
  $FilterLocationDev = $('.js-location-filter-dropdown');
  $SelectedDropdown = $(".js-filter-selected-dropdown");

  $FilterBtn.unbind("click");
  $DownloadBtn.unbind("click");
  $FilterLocationDev.unbind("click");

  $FilterBtn.on('click', (event) => {
    const el = $(event.currentTarget);
    event.preventDefault();
    const dataBox = el.data('filterBox');
    el.toggleClass(active);
    isActive = el.hasClass(active);
    if(callback) callback(isActive);
    $(`[data-filter-drop='${dataBox}']`).toggleClass(active);
    if(performInstantly) {
      $(`[data-filter-drop='${dataBox}']`).show();
      $SelectedDropdown.hide();
      performInstantly = false;
    } else {
      $(`[data-filter-drop='${dataBox}']`).slideToggle();
      if(isActive) {
        $SelectedDropdown.slideUp();
      } else {
        $SelectedDropdown.slideDown();
      }
    }
  });

  $DownloadBtn.on('click', (event) => {
    const el = $(event.currentTarget);
    event.preventDefault();
    el.toggleClass(active);
    el.siblings($Download).slideToggle();
  });

  $FilterLocationDevBtn.on('click', (event) => {
    const el = $(event.currentTarget);
    event.preventDefault();
    el.toggleClass(active);
    $FilterLocationDev.slideToggle();
  });
}

export function loadFilterDropdown(open = false, callback) {
  isActive = open;

  if(open) {
    if(callback) callback(isActive);
    performInstantly = true;
    refreshVariablesAndRebindEvents(callback);
    $FilterBtn.trigger("click");
  } else {
    refreshVariablesAndRebindEvents(callback);
  }
}

$(document).ready(() => {
  loadFilterDropdown();
});
