import whenDomReady from 'when-dom-ready';

const waysToBuyLinks = document.querySelectorAll('.js-ways-to-buy-nav');
const waysToBuyContents = document.querySelectorAll('.js-content-grid');
const summaries = document.querySelectorAll('.js-summary');
const anchorLength = window.location.hash.length;
const defaultTenure = document.querySelector('.js-default-tenure');
let wayToBuyExists = false;

function refreshActiveLink(links, wayToBuy) {
  Array.from(links).forEach((link) => {
    if (link.hasAttribute('data-content')) {
      if (link.dataset.content === wayToBuy) {
        link.classList.add('is-active');
        wayToBuyExists = true;
      } else {
        link.classList.remove('is-active');
      }
    }
  });

  if (!wayToBuyExists && defaultTenure) {
    defaultTenure.classList.add('is-active');
  }
}

function refreshContentGrids(wayToBuy) {
  if (!wayToBuyExists && defaultTenure) {
    Array.from(waysToBuyContents).forEach((content) => {
      if (content.hasAttribute('data-content')) {
        if (content.dataset.content === defaultTenure.dataset.content) {
          content.classList.remove('hidden');
          setRegisterInterestFormButton(defaultTenure.dataset.content);
        } else {
          content.classList.add('hidden');
        }
      }
    });
  } else {
    Array.from(waysToBuyContents).forEach((content) => {
      if (content.hasAttribute('data-content')) {
        if (content.dataset.content === wayToBuy) {
          content.classList.remove('hidden');
          setRegisterInterestFormButton(wayToBuy);
        } else {
          content.classList.add('hidden');
        }
      }
    });
  }

  Array.from(summaries).forEach((content) => {
    if (content.hasAttribute('data-content')) {
      if (content.dataset.content === wayToBuy) {
        content.classList.remove('hidden');
        setRegisterInterestFormButton(wayToBuy);
      } else {
        content.classList.add('hidden');
      }
    }
  });

  if (window.reInitialiseStepNavigation) {
    window.reInitialiseStepNavigation();
  }
}

function setRegisterInterestFormButton(wayToBuy) {
  const waysToBuyRadioButtons = document.querySelectorAll('.js-tenure-radio-button');
  if (waysToBuyRadioButtons) {
    Array.from(waysToBuyRadioButtons).forEach((radioButton) => {
      if (radioButton.hasAttribute('data-tenure')) {
        if (radioButton.dataset.tenure === wayToBuy) {
          radioButton.setAttribute('checked', '');
        }
      }
    });
  }
}

function initialise() {
  const anchoredWayToBuy =
    anchorLength > 0
      ? window.location.hash.substring(1, anchorLength)
      : defaultTenure.dataset.content;
  refreshActiveLink(waysToBuyLinks, anchoredWayToBuy);
  setRegisterInterestFormButton(anchoredWayToBuy);
  refreshContentGrids(anchoredWayToBuy);
}

if (waysToBuyLinks && waysToBuyContents) {
  Array.from(waysToBuyLinks).forEach((link) => {
    link.addEventListener('click', () => {
      if (link.hasAttribute('data-content')) {
        refreshContentGrids(link.dataset.content);
        refreshActiveLink(waysToBuyLinks, link.dataset.content);
      }
    });
  });
}

whenDomReady().then(initialise);
