import $ from 'jquery';

$(document).ready(() => {
  const $numberOfApplicantsSelector = $('input[name="NumberOfApplicants"]');

  if (!$numberOfApplicantsSelector.length) {
    return;
  }

  updateNumberOfApplicants();
  $numberOfApplicantsSelector.change(updateNumberOfApplicants);

  function updateNumberOfApplicants() {
    const $numberOfApplicantsSelectorChecked = $('input[name="NumberOfApplicants"]:checked');
    const $applicant2AccomodationContainer = $('.js-applicant-2-accomodation');
    const $applicant2AccomodationSelector = $applicant2AccomodationContainer.find('select');

    if ($numberOfApplicantsSelectorChecked.val() === '1') {
      $applicant2AccomodationContainer.addClass('field-container--disabled');
      $applicant2AccomodationSelector.attr('disabled', 'disabled');
    } else {
      $applicant2AccomodationContainer.removeClass('field-container--disabled');
      $applicant2AccomodationSelector.removeAttr('disabled');
    }
  }
});
