import $ from 'jquery';
import loadGoogleMapsApi from 'load-google-maps-api-2';
import _ from 'underscore';


if (document.getElementById('LocationMapLegacy') !== null) {
  // console.log("init legacy map");
  initialise();
}

async function initialise() {
  const googleMaps = await loadGoogleMapsApi({
    key: 'AIzaSyDy1qytJ6hg9Ud1mhFXegeTWgOajD9EFV8',
    libraries: ['places'],
    version: '3',
  });

  const { Marker, SQUARE_PIN } = await import('vendor/map-icons');

  window.locationMap = {};
  window.mapFilters = {};

  class LocationMapLegacy {
    constructor() {
      this.map = '';
      this.mapData = '';
      this.mapOptions = '';
      this.placesService = '';
      this.infoWindow = '';
      this.selectedFilters = [];
      this.lastFilter = {};
    }

    init() {
      this.loadMap();
      $('.map-filters input[type="checkbox"]').click((e) => {
        this.toggleMapFilter(e.target);
      });

      const noPoi = [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ];
      this.map.setOptions({ styles: noPoi });
    }

    loadMap() {
      this.mapOptions = {
        zoom: 15,
        draggable: true,
        scrollwheel: false,
        // eslint-disable-next-line max-len
        center: new googleMaps.LatLng(window.mapProps.latitude, window.mapProps.longitude),
        streetViewControl: true,
        mapTypeControl: true,
        mapTypeId: googleMaps.MapTypeId.ROADMAP,
        zoomControl: true,
        zoomControlOptions: {
          position: googleMaps.ControlPosition.RIGHT_BOTTOM,
        },
        clickableIcons: false,
        panControl: true,
      };

      // eslint-disable-next-line max-len
      this.map = new googleMaps.Map(document.getElementById('LocationMapLegacy'), this.mapOptions);
      const iconLabel = `<span class="map-icon ${window.mapProps.mapIcon}"></span>`;
      const marker = new Marker({
        // eslint-disable-next-line max-len
        position: new googleMaps.LatLng(window.mapProps.latitude, window.mapProps.longitude),
        title: window.mapProps.name,
        // toggle to switch on custom icon
        icon: {
          path: SQUARE_PIN,
          fillColor: '#FFFFFF',
          fillOpacity: 1,
          strokeColor: '',
          strokeWeight: 0,
        },
        map_icon_label: iconLabel,
      });
      marker.setMap(this.map);
    }

    getPlaces(addItems) {
      if (!addItems) {
        this.removePlaceMarkers();
      } else {
        this.addPlaceMarkers();
      }
    }

    removePlaceMarkers() {
      // Add marker to global selected list
      // eslint-disable-next-line arrow-body-style
      let { markers } = _.find(this.selectedFilters, (d) => {
        return d.name === this.lastFilter.id;
      });

      for (let i = 0; i < markers.length; i += 1) {
        markers[i].setMap(null);
      }
      markers = [];
    }

    addPlaceMarkers() {
      if (this.selectedFilters.length > 0) {
        // eslint-disable-next-line arrow-body-style
        const searchTypes = _.find(this.selectedFilters, (d) => {
          return d.name === this.lastFilter.id;
        }).types;
        const request = {
          // eslint-disable-next-line max-len
          latitude: window.mapProps.latitude,
          longitude: window.mapProps.longitude,
          radius: '2000',
          types: searchTypes,
          icon: $(this.lastFilter).data('map-icon-class'),
          filtername: this.lastFilter.id,
          locationName: window.mapProps.name,
        };

        this.infoWindow = new googleMaps.InfoWindow();
        // Request the places from the server.
        $.ajax({
          type: 'POST',
          url: '/umbraco/api/maplocations/getplacesajax',
          data: request,
        }).done((results) => {
          this.addResultsToMap(results);
        });
      }
    }

    addResultsToMap(results) {
      for (let i = 0; i < results.length; i += 1) {
        const result = results[i];
        this.addMarker(result);
      }
    }

    addMarker(result) {
      const iconLabel = `<span class="map-icon ${result.iconClass}"></span>`;
      const marker = new Marker({
        map: this.map,
        position: result.place.geometry.location,
        icon: {
          path: SQUARE_PIN,
          fillColor: '#5C6366',
          fillOpacity: 1,
          strokeColor: '',
          strokeWeight: 0,
        },
        map_icon_label: iconLabel,
      });

      googleMaps.event.addListener(marker, 'click', () => {
        this.infoWindow.setContent(result.place.name);
        this.infoWindow.open(this.map, marker);
      });

      // Add marker to global selected list
      // eslint-disable-next-line arrow-body-style
      _.find(this.selectedFilters, (d) => {
        return d.name === this.lastFilter.id;
      }).markers.push(marker);
    }

    toggleMapFilter(filter) {
      this.lastFilter = filter;

      const filterItem = {
        name: this.lastFilter.id,
        types: filter.value.split(','),
        markers: [],
      };

      this.selectedFilters.push(filterItem);
      this.getPlaces(filter.checked);
    }
  }

  $(document).ready(() => {
    window.locationMap = new LocationMapLegacy();
    window.locationMap.init();
  });
}
