/* eslint-disable no-console */
import $ from 'jquery';

$(document).ready(() => {
  const $modalBtn = $('.js-modal-btn');
  const $modalCloseBtn = $('.js-modal-close-btn');
  const $modalCalcBtn = $('.js-show-calc');
  const $modalCalc = $('.js-modal-calc');
  const $modalCalcClose = $('.js-modal-calc .js-close-calc');
  const active = 'is-active';
  const noScroll = 'no-scroll';
  const isTrue = true;

  $modalBtn.on('click', (event) => {
    const el = $(event.currentTarget);
    event.preventDefault();
    const dataBox = el.data('modal');

    if (isTrue) {
      $(`[data-modal-box='${dataBox}']`).addClass(active);
      $('body').addClass(noScroll);
    }
  });

  $modalCloseBtn.on('click', (event) => {
    const el = $(event.currentTarget);
    event.preventDefault();
    const dataBox = el.data('modal');
    $(`[data-modal-box='${dataBox}']`).removeClass(active);
    $('body').removeClass(noScroll);
  });

  $modalCalcBtn.on('click', (event) => {
    event.preventDefault();
    $modalCalc.addClass(active);
    $('body').addClass(noScroll);
  });

  $modalCalcClose.on('click', (event) => {
    event.preventDefault();
    $modalCalc.removeClass(active);
    $('body').removeClass(noScroll);
  });
});
