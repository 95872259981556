/* eslint-disable no-console */
/* eslint-disable max-len */
import $ from 'jquery';

const $applyToSellButtons = $('.js-apply-to-sell');

class ApplyToSellButton {
  constructor(ctrl) {
    this.$control = ctrl;
  }

  init() {
    this.$control.click(() => {
      this.applyForStaircasing();
      return false;
    });
  }

  applyForStaircasing() {
    // Add/remove the item
    $.ajax({
      type: 'POST',
      url: '/umbraco/surface/account/applytosell',
    })
    .done((data) => {
      if (data.redirect) {
        window.location = data.redirectUrl;
      } else {
        this.$control.unbind('click');
        this.$control.click(() => false);
        // Display success
        this.$control.html(data.btnText);
      }
    });
  }
}

$(document).ready(() => {
  if ($applyToSellButtons != null) {
    $applyToSellButtons.each((i, elem) => {
      const button = new ApplyToSellButton($(elem));
      button.init();
    });
  }
});
