import whenDomReady from 'when-dom-ready';

function initialise(){
    const registerMoreArea = document.querySelector('.js-register-more-form');
    const registerMoreBtnArea = document.querySelector('.js-register-more-btn-area');
    const registerMoreBtn = document.querySelector('.js-register-more-btn');
    const ACTIVE = 'is-active';

    registerMoreBtn.addEventListener('click', () => {
        registerMoreArea.classList.add(ACTIVE);
        registerMoreBtnArea.classList.add(ACTIVE);
    });

}

whenDomReady().then(initialise);