import whenDomReady from 'when-dom-ready';

function initialise(){
    const showAllBtn = document.querySelector('.js-show-all-description');
    const ctaContentClamp = document.querySelector('.js-description-clamp');
    const ACTIVE = 'show-more';
    const RESTRICT = 'restrict';

    const ContentHeight = ctaContentClamp.offsetHeight;
    if(ContentHeight > 100) {
        ctaContentClamp.classList.add(RESTRICT);
        showAllBtn.classList.remove('hidden');
    }

    showAllBtn.addEventListener('click', () => {
        ctaContentClamp.classList.toggle(ACTIVE);
        showAllBtn.textContent = ctaContentClamp.classList.contains(ACTIVE) ? 'Show less' : 'Show more';
    });

}

whenDomReady().then(initialise);