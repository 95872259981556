/* eslint-disable no-console */
/* eslint-disable max-len */
import $ from 'jquery';
import AdditionalDetails from './init-additional-details';

const $registerInterestButtons = $('.js-register-interest-btn');
const $formContainer = $('body .additional-details-form .form-container');

class RegisterInterestButton {
  constructor(ctrl) {
    this.$control = ctrl;
    this.developmentDocId = ctrl.data('development-id');
    this.plotDocId = ctrl.data('plot-id');
    this.applicationTypeId = ctrl.data('application-type-id');
    this.AdditionalDetails = new AdditionalDetails($formContainer, this.developmentDocId, this.plotDocId);
  }

  init() {
    this.$control.click(() => {
      this.registerInterest();
      return false;
    });
  }

  registerInterest() {
    // Build the request model from the URI query parameters.
    const requestModel = {
      developmentDocId: this.developmentDocId,
      plotDocId: this.plotDocId,
      applicationTypeId: this.applicationTypeId,
    };

    this.$control.html(`
        Please wait...
        <img class="icon" src="/SiteFiles/src/ui/img/svg/loading.svg">`
    );

    // Add/remove the item
    $.ajax({
      type: 'POST',
      url: '/umbraco/surface/account/registerinterest',
      data: requestModel,
    })
    .done((data) => {
      if (data.redirect) {
        window.location = data.redirectUrl;
      } else if (!data.error) {
        if (data.modalForm.length > 0) {
          this.AdditionalDetails.bindForm(data);
          this.$control.html(`
              Register interest
              <svg class="icon" viewBox="0 0 32 32"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/SiteFiles/src/ui/img/svg/note-icon.svg#note"></use></svg>`
          );
        } else {
          this.$control.unbind('click');
          this.$control.click(() => {
            window.location = data.registeredPropertiesUrl;
            return false;
          });

          this.$control.html(data.btnText);
        }
      } else {
        this.$control.html(data.btnText);
      }
    });
  }
}


$(document).ready(() => {
  if ($registerInterestButtons != null) {
    $registerInterestButtons.each((i, elem) => {
      const button = new RegisterInterestButton($(elem));
      button.init();
    });
  }
});
