/* eslint-disable no-console */
import $ from 'jquery';
import 'jquery.cookie';

$(document).ready(() => {
  const $cookies = $('.js-cookie-popup');
  const $close = $('.js-cookie-popup-close');
  const IS_ACTIVE = 'is-active';

  const cookieValue = $.cookie('cookiebanner');

  if (cookieValue !== '1') {
    $cookies.addClass(IS_ACTIVE);
  } else {
    $cookies.removeClass(IS_ACTIVE);
  }

  $close.click((e) => {
    e.preventDefault();
    $cookies.removeClass(IS_ACTIVE);
    $.cookie('cookiebanner', '1', {
      expires: 365,
      path: '/',
    });
  });

  setTimeout(() => {
    if ($cookies.hasClass(IS_ACTIVE)) {
      $cookies.removeClass(IS_ACTIVE);
      $.cookie('cookiebanner', '1', {
        expires: 365,
        path: '/',
      });
    }
  }, 12000);

  $(window).scroll(() => {
    if ($cookies.hasClass(IS_ACTIVE)) {
      $cookies.removeClass(IS_ACTIVE);
      $.cookie('cookiebanner', '1', {
        expires: 365,
        path: '/',
      });
    }
  });
});
