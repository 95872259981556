import $ from 'jquery';

$(document).ready(() => {
  const $html = $('html');
  const $advancedSearch = $('.js-advanced-search');
  const $openAdvancedSearch = $('.js-advanced-filter-btn');
  const $closeAdvancedSearch = $('.js-close-advanced-search');

  const ACTIVE = 'is-active';
  const STICKY = 'is-sticky';

  $openAdvancedSearch.on('click', () => {
    $html.addClass(STICKY);
    $advancedSearch.addClass(ACTIVE);
  });

  $closeAdvancedSearch.on('click', () => {
    $html.removeClass(STICKY);
    $advancedSearch.removeClass(ACTIVE);
  });
});
