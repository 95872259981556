/* eslint-disable no-console */
import $ from 'jquery';

$(document).ready(() => {
  const $accordionBtn = $('.js-toggle-accordion');
  const $Accordion = $('.js-accordion-section');
  const active = 'is-active';

  $accordionBtn.on('click tap', (e) => {
    const el = $(e.currentTarget);
    e.preventDefault();
    el.toggleClass(active);
    el.siblings($Accordion).slideToggle();
  });

  $accordionBtn.each((e, el) => {
    if ($(el).hasClass(active)) {
      $(el).siblings($Accordion).slideToggle();
    }
  });
});
