import $ from 'jquery';
import URI from 'urijs';
import {
  getIsActive,
  loadFilterDropdown,
  refreshVariablesAndRebindEvents,
} from './init-filter-dropdown';
import { bindLazyLoads } from './init-lazy-load';

// Number of articles to load
let currentPage = 1;

// Blog categories
let selectedCategories = [];

const $blogListingGrid = $('.js-blog-listing-grid');
const $blogKeywordField = $('.js-blog-keyword');
const $blogKeywordBtn = $('.js-blog-keyword-btn');
const $searchResultsUrl = $('.js-blog-search-url');
let $blogFilters = $('.js-blog-filter');

$(document).ready(() => {
  if ($blogListingGrid.length === 0) {
    if ($searchResultsUrl.length === 0) {
      return;
    }

    $blogKeywordBtn.click(() => {
      const searchResultsUrl = new URI($searchResultsUrl.val());
      searchResultsUrl.addSearch('searchkeyword', $blogKeywordField.val());
      window.location.href = searchResultsUrl.toString();
    });

    $blogKeywordField.keypress(event => {
      if (event.which === 13) {
        const searchResultsUrl = new URI($searchResultsUrl.val());
        searchResultsUrl.addSearch('searchkeyword', $blogKeywordField.val());
        window.location.href = searchResultsUrl.toString();
      }
    });

    return;
  }

  if ($blogFilters.length > 0) {
    $blogFilters.each((i, filter) =>
      $(filter).on('click', () => {
        handleClick(filter);
      })
    );
  }

  requestBlogPosts();
  requestCategoryFilterUpdate();

  // Bind search button click event.
  $blogKeywordBtn.click(() => {
    updateUrl();
    requestBlogPosts();
    scrollToResults();
  });

  // Bind search box enter press event.
  $blogKeywordField.keypress(event => {
    if (event.which === 13) {
      event.preventDefault();
      updateUrl();
      requestBlogPosts();
      scrollToResults();
    }
  });
});

function scrollToResults() {
  const $siteHeader = $('.js-site-header');
  const $blogGridContainer = $('.js-blog-grid-container');

  $('html, body').animate(
    {
      scrollTop: $blogGridContainer.offset().top - $siteHeader.height(),
    },
    250
  );
}

function handleClick(filter) {
  const dataValue = $(filter).find('span').data('filter'); // The clicked category //
  if (!selectedCategories.includes(dataValue)) {
    selectedCategories.push(dataValue);
  } else {
    selectedCategories = selectedCategories.filter(category => category !== dataValue);
  }
  // Reset to First page
  currentPage = 1;

  updateUrl();
  requestBlogPosts();
  requestCategoryFilterUpdate();
}

function handlePagingClick(button) {
  const dataValue = $(button).data('page');
  currentPage = Number.parseInt(dataValue, 10);

  updateUrl();
  requestBlogPosts();
  scrollToResults();
}

function requestCategoryFilterUpdate() {
  const $blogFiltersContainer = $('.js-blog-filters-container');

  const requestModel = { selectedCategories, isActive: getIsActive() };

  $.ajax({
    type: 'POST',
    url: '/umbraco/surface/blog/renderblogcategoriesfilter',
    data: requestModel,
  }).done(data => {
    $blogFiltersContainer.html(data);

    $blogFilters = $('.js-blog-filter'); // Re-get the filters //
    // Re-bind the onClick events //
    $blogFilters.each((i, filter) =>
      $(filter).on('click', () => {
        handleClick(filter);
      })
    );

    refreshVariablesAndRebindEvents();
    loadFilterDropdown(getIsActive(), isOpen => {
      if (isOpen) $blogFiltersContainer.addClass('is-active');
      else $blogFiltersContainer.removeClass('is-active');
    });
  });
}

function requestBlogPosts() {
  const currentUri = new URI(window.location.href);
  const urlParams = currentUri.search(true);

  if (urlParams.categories) {
    selectedCategories = urlParams.categories.split(',').map(x => x.replace(/-/g, ' '));
  }

  // Build the request model from the URI query parameters.
  const requestModel = {
    // eslint-disable-next-line max-len
    pageIndex: urlParams.p === undefined ? 1 : urlParams.p,
    currentNodeId: $('.js-current-node').val(),
    categories: selectedCategories,
  };

  // Request the news articles from the server.
  $.ajax({
    type: 'POST',
    url: '/umbraco/surface/blog/getbloglistingajax',
    data: requestModel,
  }).done(data => {
    // Bind the response HTML to the container.
    $blogListingGrid.html(data);

    // update canonical
    const canonicalLink = document.querySelector("link[rel='canonical']");
    if (canonicalLink) {
      let link = canonicalLink.href;
      const regex = /\?p=\d*/i;
      link = link.replace(regex, '');
      canonicalLink.href = `${link}?p=${requestModel.pageIndex}`;
    }

    // Rebind Paginations
    const $pagingButtons = $('.js-btn-paging');
    $pagingButtons.each((i, pagingButton) =>
      $(pagingButton).on('click', e => {
        e.preventDefault();
        handlePagingClick(pagingButton);
      })
    );

    // Rebind the lazy loads //
    bindLazyLoads();
  });
}

function updateUrl(reset) {
  const currentUri = new URI(window.location.href);

  // Remove all URI parameters.
  currentUri.removeSearch(['p']);
  currentUri.removeSearch(['categories']);

  // If restting, don't add any URI parameters.
  if (reset === undefined || reset !== true) {
    if (currentPage !== 1) {
      currentUri.addSearch('p', currentPage);
    }

    if (selectedCategories.length > 0) {
      currentUri.addSearch(
        'categories',
        selectedCategories.map(x => x.replace(/ /g, '-')).join(',')
      );
    }
  }

  // Update the URI state.
  window.history.replaceState(null, null, currentUri.toString());
}
