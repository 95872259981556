import $ from 'jquery';
import 'ion-rangeslider/js/ion.rangeSlider';

$(document).ready(() => {
  const $distanceSlider = $('.js-distance-slider');
  const $distanceSliderContainer = $('.js-distance-slider-container');
  const $bedroomSlider = $('.js-bedroom-slider');
  const $boroughSelector = $('.js-borough-selector');
  const $bedroomsMin = $('.js-min-bedrooms');
  const $bedroomsMax = $('.js-max-bedrooms');  

  const $lengthSlider = $('.js-loan-slider');
  const $lengthText = $('.js-loan-text');

  if ($distanceSlider.length) {
    const sliderValues = [0, 1, 2, 3, 4, 5, 10, 20];

    $distanceSlider.ionRangeSlider({
      grid: true,
      hide_min_max: true,
      hide_from_to: true,
      values: sliderValues,
      prettify: (num) => {
        if (num === sliderValues[sliderValues.length - 1]) {
          return `${num}+`;
        }

        return num;
      },
    });

    updateDistanceSlider();
    $boroughSelector.change(updateDistanceSlider);
  }

  if ($bedroomSlider.length) {
    $bedroomSlider.ionRangeSlider({
      type: 'double',
      grid: true,
      hide_min_max: true,
      hide_from_to: true,
      values: [
        'Studio', '1',
        '2', '3',
        '4', '5+',
      ],
      onStart: updateBedroomsInputs,
      onChange: updateBedroomsInputs,
      from: $bedroomsMin.val(),
      to: $bedroomsMax.val(),
    });
  }

  if ($lengthSlider.length) {

    const loanLenMin = 0;
    const loanLenMax = 35;
    const initialVal = 20;

    $lengthSlider.ionRangeSlider({
      hide_min_max: true,
      hide_from_to: true,
      grid: true,
      grid_num: 1,
      min: loanLenMin,
      max: loanLenMax,
      from: initialVal,
      prettify: (num) => `${num} Years`,
      onChange: updateLoanInput,
    });

    const instance = $lengthSlider.data("ionRangeSlider");
    $lengthText.prop('value', initialVal);

    $lengthText.on("input", () => {
      let val = parseInt($lengthText.prop("value"), 10);
      
      // validate
      if (val < loanLenMin) {
          val = loanLenMin;
      } else if (val > loanLenMax) {
          val = loanLenMax;
      }

      instance.update({
          from: val
      });
      $lengthText.prop('value', val);
    });
  }

  function updateBedroomsInputs(data) {
    const {from} = data;
    const {to} = data;

    if (from === 0) {
      $bedroomsMin.prop('value', '');
    } else {
      $bedroomsMin.prop('value', from);
    }

    if (to === 5) {
      $bedroomsMax.prop('value', '');
    } else {
      $bedroomsMax.prop('value', to);
    }
  }

  function updateDistanceSlider() {
    const sliderInstance = $distanceSlider.data('ionRangeSlider');

    if ($boroughSelector.val() === '') {
      $distanceSliderContainer.addClass('field-container--disabled');
      sliderInstance.update({
        disable: true,
      });
    } else {
      $distanceSliderContainer.removeClass('field-container--disabled');
      sliderInstance.update({
        disable: false,
      });
    }
  }

  function updateLoanInput(data) {
    const { from } = data;
    $lengthText.prop('value', from);
  }
});
