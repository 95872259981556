import whenDomReady from 'when-dom-ready';
import BeerSlider from 'beerslider';

function initialise() {
  const sliders = document.querySelectorAll('.js-beer-slider')
  let slider;

  for(slider of sliders)
  {
  const imgSlider = new BeerSlider( slider );
  console.log(imgSlider);
  }
}

whenDomReady().then(initialise);

