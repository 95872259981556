/* eslint-disable no-plusplus */
window.addEventListener('load', () => {
  const initialUrl = window.location.href;
  const tenureSelects = document.querySelectorAll('.js-tenure-select');
  const tenureOptions = tenureSelects[0].options;

  if (tenureOptions.length>1 && initialUrl.includes(`#`)){
    for (let i = 0; i < tenureOptions.length; i++) {
      const tenureValue = tenureOptions[i].value;
      const contentOption = document.querySelectorAll(`[data-content=${tenureValue}]`);
      const titleOption = document.querySelectorAll(`[data-title=${tenureValue}]`);

      if (initialUrl.includes(`#${tenureValue}`)) {
        tenureSelects.forEach((e) => {e.value = `${tenureValue}`});

        contentOption.forEach((x) => {
          x.classList.remove('hidden');
        });

        titleOption.forEach((x) => {
          x.classList.remove('hidden');
        });
        
      } else {
        contentOption.forEach((x) => {
          x.classList.add('hidden');
        });

        titleOption.forEach((x) => {
          x.classList.add('hidden');
        });
      }
    }
    const subNavItemsNodeList = document.querySelectorAll('.development-subnav__items a');
    const subNavItemsArray = Array.from(subNavItemsNodeList);
    const areaItems = document.querySelectorAll('.js-step-items');
    
    subNavItemsArray.forEach((item, index) => {
      if (areaItems[index].closest('.js-content-grid')) {
        if (item.classList.contains('hidden')) {
          item.classList.remove('hidden');
        } else {
          item.classList.add('hidden');
        } 
      }    
    })
  }

  tenureSelects.forEach((x) =>
    x.addEventListener('change', function tenureUpdated() {
      const url = initialUrl.split('#')[0];
      window.location = `${url}#${this.value}`;
      const subNavItemsNodeList = document.querySelectorAll('.development-subnav__items a');
      const subNavItemsArray = Array.from(subNavItemsNodeList);
      const areaItems = document.querySelectorAll('.js-step-items');
      
      subNavItemsArray.forEach((item, index) => {
        if (areaItems[index].closest('.js-content-grid')) {
          if (item.classList.contains('hidden')) {
            item.classList.remove('hidden');
          } else {
            item.classList.add('hidden');
          } 
        }    
      });

      // Synchronise the values across both the selects used on the page (main nav and subnav) //
      tenureSelects.forEach((y) => {
        if (y.id !== x.id) {
          document.getElementById(y.id).value = x.value;
        }
      });

      for (let i = 0; i < tenureOptions.length; i++) {
        const contentOption = document.querySelector(`[data-content=${tenureOptions[i].value}]`);
        const titleOption = document.querySelector(`[data-title=${tenureOptions[i].value}]`);

        // Ensure the correct div is being shown on the page //
        if (tenureOptions[i].value === x.value) {
          titleOption.classList.remove('hidden');
          contentOption.classList.remove('hidden');
          document
            .querySelector(`div.js-content-grid[data-content='${tenureOptions[i].value}']`)
            .classList.remove('hidden');
        } else {
          titleOption.classList.add('hidden');
          contentOption.classList.add('hidden');
          document
            .querySelector(`div.js-content-grid[data-content='${tenureOptions[i].value}']`)
            .classList.add('hidden');
        }
      }
    }),
  );
});
