import whenDomReady from 'when-dom-ready';

function initialise() {
  const brochureDropdown = document.querySelectorAll('.js-brochure-dropdown');
  const ACTIVE = 'active';

  if (brochureDropdown.length) {
    Array.from(brochureDropdown).forEach((drop) => {
      drop.addEventListener('click', () => {
        if (drop.classList.contains(ACTIVE)) {
          drop.classList.remove(ACTIVE);
        } else {
          drop.classList.add(ACTIVE);
        }
      });
    });
  }
}

whenDomReady().then(initialise);
