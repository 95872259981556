/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import $ from 'jquery';
import loadGoogleMapsApi from 'load-google-maps-api-2';
import find from 'lodash/find';
import whenDomReady from 'when-dom-ready';

whenDomReady(initialise);

/** This function sets up Intersection Observers to lazy init the maps. */
function initialise() {
  const elements = document.getElementsByClassName('js-location-map');

  Array.from(elements).forEach((element) => {
    const observer = new IntersectionObserver((entries) => {
      if (entries[0] && entries[0].isIntersecting) {
        initialiseMap();
      }
    });
    observer.observe(element);
  });
}

/** This function handles actually initialising the map. */
async function initialiseMap() {
  const googleMaps = await loadGoogleMapsApi({
    key: 'AIzaSyDy1qytJ6hg9Ud1mhFXegeTWgOajD9EFV8',
    libraries: ['places'],
    version: '3',
  });

  const { Marker } = await import('vendor/map-icons');
  window.locationMap = [];
  window.mapFilters = {};

  class LocationMap {
    constructor(longitude, latitude, name, mapIconColour, mapSize) {
      this.map = '';
      this.mapData = '';
      this.mapOptions = '';
      this.placesService = '';
      this.infoWindow = '';
      this.selectedFilters = [];
      this.lastFilter = {};
      this.lastFilters = {};
      this.mapIconFgColor = '';
      this.latitude = latitude;
      this.longitude = longitude;
      this.name = name;
      this.mapSize = mapSize;

      this.mapIconColour = mapIconColour;
    }

    init(key) {
      this.key = key;
      if (!this.mapIconColour) {
        this.mapIconColour = window[`mapProps${key}`].mapIcon
          ? window[`mapProps${key}`].mapIcon
          : '#008484';
      }
      this.mapIconFgColor = window[`mapProps${key}`]?.mapIconFg
        ? window[`mapProps${key}`].mapIconFg
        : '#FFFFFF';

      this.loadMap(key);
      $('.map-filters input[type="checkbox"]').click((e) => {
        this.toggleMapFilter(e.target);
      });

      const noPoi = [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ];
      this.map.setOptions({ styles: noPoi });
    }

    loadMap(key) {
      this.mapOptions = {
        zoom: 15,
        draggable: true,
        scrollwheel: false,
        // eslint-disable-next-line max-len
        center: new googleMaps.LatLng(
          this.latitude || window[`mapProps${key}`].latitude,
          this.longitude || window[`mapProps${key}`].longitude,
        ),
        streetViewControl: true,
        mapTypeControl: true,
        mapTypeId: googleMaps.MapTypeId.ROADMAP,
        zoomControl: true,
        zoomControlOptions: {
          position: googleMaps.ControlPosition.RIGHT_BOTTOM,
        },
        clickableIcons: false,
        panControl: true,
      };

      // eslint-disable-next-line max-len
      this.map = new googleMaps.Map(
        document.querySelector(`#LocationMap[data-key="${key}"]`),
        this.mapOptions,
      );
      const iconLabel = `<span class="map-icon">${peabodyIcon(this.mapIconFgColor)}</span>`;
      const iconLabelHeight = this.mapSize === 'narrow' ? 55 : 80;
      const marker = new Marker({
        // eslint-disable-next-line max-len
        position: new googleMaps.LatLng(
          this.latitude || window[`mapProps${key}`].latitude,
          this.longitude || window[`mapProps${key}`].longitude,
        ),
        title: this.name || window[`mapProps${key}`].name,
        // toggle to switch on custom icon
        map_icon_label: iconLabel,
        icon: {
          url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56 80" style="enable-background:new 0 0 56 80;" xml:space="preserve"><g transform="matrix(1, 0, 0, 1, 0, 0)"><path id="Union_2-2" d="M28,78c-1.1,0-2.1-0.6-2.7-1.5c-6.8-11.1-22.5-38-22.5-49.2C2.7,13.5,13.9,2.1,27.8,2S53,13,53.2,26.9c0,0.1,0,0.3,0,0.4c0,11.1-15.7,38.1-22.5,49.2C30.1,77.5,29.1,78,28,78L28,78z" fill="${this.mapIconColour}" stroke="${this.mapIconFgColor}" stroke-width="1.5"/></g></svg>`,
          )}`,
          size: new googleMaps.Size(56, 80),
          scaledSize: new googleMaps.Size(56, 80),
          anchor: new googleMaps.Point(28, iconLabelHeight),
          labelOrigin: new googleMaps.Point(0, 0),
        },
      });
      marker.setMap(this.map);
    }

    getPlaces(addItems) {
      if (!addItems) {
        this.removePlaceMarkers();
      } else {
        this.addPlaceMarkers();
      }
    }

    removePlaceMarkers() {
      // Add marker to global selected list
      // eslint-disable-next-line arrow-body-style
      let { markers } = find(this.selectedFilters, (d) => {
        return d.name === this.lastFilter.id;
      });

      for (let i = 0; i < markers.length; i += 1) {
        markers[i].setMap(null);
      }
      markers = [];
    }

    addPlaceMarkers() {
      if (this.selectedFilters.length > 0) {
        // eslint-disable-next-line arrow-body-style
        const searchTypes = find(this.selectedFilters, (d) => {
          return d.name === this.lastFilter.id;
        }).types;
        const request = {
          // eslint-disable-next-line max-len
          latitude: this.latitude || window[`mapProps${this.key}`].latitude,
          longitude: this.longitude || window[`mapProps${this.key}`].longitude,
          radius: '2000',
          types: searchTypes,
          icon: $(this.lastFilter).data('map-icon-class'),
          filtername: this.lastFilter.id,
          locationName: this.name || window[`mapProps${this.key}`].name,
        };

        this.infoWindow = new googleMaps.InfoWindow();
        // Request the places from the server.
        $.ajax({
          type: 'POST',
          url: '/umbraco/api/maplocations/getplacesajax',
          data: request,
        }).done((results) => {
          this.addResultsToMap(results);
        });
      }
    }

    addResultsToMap(results) {
      for (let i = 0; i < results.length; i += 1) {
        const result = results[i];
        this.addMarker(result);
      }
    }

    addMarker(result) {
      const iconLabel = `<span class="map-icon ${result.iconClass}"></span>`;
      const marker = new Marker({
        map: this.map,
        position: result.place.geometry.location,
        icon: {
          url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(
            `<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 56 80" style="enable-background:new 0 0 56 80;" xml:space="preserve"><g transform="matrix(1, 0, 0, 1, 0, 0)"><path id="Union_2-2" d="M28,78c-1.1,0-2.1-0.6-2.7-1.5c-6.8-11.1-22.5-38-22.5-49.2C2.7,13.5,13.9,2.1,27.8,2S53,13,53.2,26.9c0,0.1,0,0.3,0,0.4c0,11.1-15.7,38.1-22.5,49.2C30.1,77.5,29.1,78,28,78L28,78z" fill="${this.mapIconColour}" stroke="${this.mapIconFgColor}" stroke-width="1.5"/></g></svg>`,
          )}`,
          size: new googleMaps.Size(56, 80),
          scaledSize: new googleMaps.Size(56, 80),
          anchor: new googleMaps.Point(28, 80),
          labelOrigin: new googleMaps.Point(0, 0),
        },
        map_icon_label: iconLabel,
      });

      googleMaps.event.addListener(marker, 'click', () => {
        this.infoWindow.setContent(result.place.name);
        this.infoWindow.open(this.map, marker);
      });

      // Add marker to global selected list
      // eslint-disable-next-line arrow-body-style
      find(this.selectedFilters, (d) => {
        return d.name === this.lastFilter.id;
      }).markers.push(marker);
    }

    toggleMapFilter(filter) {
      const items = filter.value.split(',');
      if (items.length === 1) {
        this.lastFilter = filter;

        const filterItem = {
          name: this.lastFilter.id,
          types: filter.value.split(','),
          markers: [],
        };

        this.selectedFilters.push(filterItem);
        this.getPlaces(filter.checked);
      } else {
        const { mapIconClass } = filter.dataset;
        const icons = mapIconClass.split(',');
        this.lastFilters = filter;

        for (let i = 0; i < items.length; i += 1) {
          if (!this.selectedFilters.find(({ name }) => name === icons[i])) {
            const filterItem = {
              name: icons[i],
              types: items[i],
              markers: [],
            };
            this.selectedFilters.push(filterItem);
          }
        }
        this.getPlacesMultiple(filter.checked);
      }
    }

    getPlacesMultiple(addItems) {
      if (!addItems) {
        this.removePlaceMarkersMultiple();
      } else {
        this.addPlaceMarkersMultiple();
      }
    }

    addPlaceMarkersMultiple() {
      if (this.selectedFilters.length > 0) {
        const value = this.lastFilters.value.split(',');
        const { mapIconClass } = this.lastFilters.dataset;
        const icons = mapIconClass.split(',');
        // eslint-disable-next-line arrow-body-style

        for (let i = 0; i < value.length; i += 1) {
          const request = {
            // eslint-disable-next-line max-len
            latitude: this.latitude || window[`mapProps${this.key}`].latitude,
            longitude: this.longitude || window[`mapProps${this.key}`].longitude,
            radius: '2000',
            types: value[i],
            icon: icons[i],
            filtername: value[i],
            locationName: this.name || window[`mapProps${this.key}`].name,
          };

          this.infoWindow = new googleMaps.InfoWindow();
          // Request the places from the server.
          $.ajax({
            type: 'POST',
            url: '/umbraco/api/maplocations/getplacesajax',
            data: request,
          }).done((results) => {
            this.addResultsToMap(results);
          });
        }
      }
    }

    removePlaceMarkersMultiple() {
      const values = this.lastFilters.value.split(',');
      const { mapIconClass } = this.lastFilters.dataset;
      const icons = mapIconClass.split(',');

      for (let i = 0; i < values.length; i += 1) {
        // eslint-disable-next-line arrow-body-style
        let { markers } = find(this.selectedFilters, (d) => {
          return d.name === icons[i];
        });

        for (let m = 0; m < markers.length; m += 1) {
          markers[m].setMap(null);
        }
        markers = [];
      }
    }
  }

  $(document).ready(() => {
    const maps = document.querySelectorAll('#LocationMap');
    for (let m = 0; m < maps.length; m += 1) {
      const { key, longitude, latitude, name, mapIconColour, mapSize } = maps[m].dataset;
      const newMap = new LocationMap(longitude, latitude, name, mapIconColour, mapSize);
      window.locationMap.push(newMap);
      newMap.init(key);
    }
  });
}

/** Wrapper around the Peabody icon SVG to apply the correct theme colour. */
function peabodyIcon(color) {
  return `
  <svg viewBox="0.61 0.59 34.9 34.9" style="width: 32px; height: 32px; margin: 4px 8px 0;">
    <g transform="translate(-9.8915086,-1.6132076)">
      <g>
        <g>
          <path fill="${color}" d="m 15.6,32 c 7.3,0 11.6,-4.3 11.6,-11.6 -7.3,0 -11.6,4.3 -11.6,11.6 z"/>
        </g>
        <g>
          <path fill="${color}" d="M 15.6,7.4 C 15.6,14.7 19.9,19 27.2,19 27.3,11.6 22.9,7.4 15.6,7.4 Z"/>
        </g>
        <g>
          <path fill="${color}" d="M 40.3,7.4 C 40.3,14.7 36,19 28.7,19 28.7,11.6 33,7.4 40.3,7.4 Z"/>
          <g>
            <path fill="${color}" d="M 30.9,9.5 C 31.2,9.2 31.4,9 31.7,8.8 31.3,6.5 30,4.3 28,2.2 c -2.1,2.1 -3.3,4.3 -3.7,6.6 0.3,0.2 0.6,0.5 0.8,0.7 1.4,1.4 2.3,3.1 2.9,5.2 0.5,-2 1.5,-3.8 2.9,-5.2 z"/>
          </g>
        </g>
        <g>
          <path fill="${color}" d="M 40.3,32 C 33,32 28.7,27.7 28.7,20.4 36,20.4 40.3,24.7 40.3,32 Z"/>
          <g>
            <path fill="${color}" d="m 38.1,22.6 c 0.3,0.3 0.5,0.5 0.7,0.8 2.3,-0.4 4.5,-1.7 6.6,-3.7 -2.1,-2.1 -4.3,-3.3 -6.6,-3.7 -0.2,0.3 -0.5,0.6 -0.7,0.8 -1.4,1.4 -3.1,2.3 -5.2,2.9 2.1,0.5 3.8,1.5 5.2,2.9 z"/>
          </g>
        </g>
        <g>
          <path fill="${color}" d="m 30.9,29.8 c -1.4,-1.4 -2.3,-3.1 -2.9,-5.2 -0.6,2.1 -1.5,3.8 -2.9,5.2 -0.3,0.3 -0.5,0.5 -0.8,0.7 0.4,2.3 1.7,4.5 3.7,6.6 2.1,-2.1 3.3,-4.3 3.7,-6.6 -0.3,-0.2 -0.6,-0.4 -0.8,-0.7 z"/>
        </g>
        <g>
          <path fill="${color}" d="m 17.8,22.6 c 1.4,-1.4 3.1,-2.3 5.2,-2.9 -2.1,-0.6 -3.8,-1.5 -5.2,-2.9 -0.3,-0.3 -0.5,-0.5 -0.7,-0.8 -2.3,0.4 -4.5,1.7 -6.6,3.7 2.1,2.1 4.3,3.3 6.6,3.7 0.2,-0.3 0.4,-0.5 0.7,-0.8 z"/>
        </g>
      </g>
    </g>
  </svg>
  `;
}
