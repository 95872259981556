class PageScrollHandler {
  constructor() {
    this.observer = new IntersectionObserver((entries) => this.handleEntries(entries));
    this.callbacks = [];
  }

  registerNewElement(element, callback, sendElementToCallback) {
    this.callbacks.push({ element, callback, sendElementToCallback });
    this.observer.observe(element);
  }

  /* built in function to add image background image url from data-src when entering viewport */
  registerBackgroundImageElement(element) {
    this.callbacks.push({ element, callback: this.handleBgImages, sendElementToCallback: true });
    this.observer.observe(element);
  }

  /* built in function to add image src from data-src when entering viewport */
  registerImageElement(element) {
    this.callbacks.push({ element, callback: this.handleSrcImages, sendElementToCallback: true });
    this.observer.observe(element);
  }

  /* built in function to add iframe src from data-src when entering viewport */
  registerIframeElement(element) {
    this.callbacks.push({ element, callback: this.handleSrcIframes, sendElementToCallback: true });
    this.observer.observe(element);
  }

  /* handle entries run when a new element is observed (comes into view) */
  handleEntries(entries) {
    Array.from(entries).forEach((event) => {
      if (!event.isIntersecting) {
        return;
      }

      const pair = this.callbacks.find((x) => x.element === event.target);

      if (pair.sendElementToCallback) {
        pair.callback(pair.element);
      } else {
        pair.callback();
      }
    });
  }

  /* built in styling functions */
  handleBgImages(element) {
    const url = element.getAttribute('data-src');
    if (url !== null && url !== '' && url !== undefined) {
      const image = new Image();
      image.src = url;
      image.addEventListener('load', () => {
        element.removeAttribute('data-src', url);
        element.setAttribute('style', `background-image: url('${url}');`);
        element.classList.add('loaded');
      });
    }
  }

  handleSrcImages(element) {
    const url = element.getAttribute('data-src');
    if (url !== null && url !== '' && url !== undefined) {
      const image = new Image();
      image.src = url;
      image.addEventListener('load', () => {
        element.removeAttribute('data-src', url);
        element.setAttribute('src', url);
        window.setTimeout(() => {
          element.classList.add('loaded');
        }, 50);
      });
    }
  }

  handleSrcIframes(element) {
    const url = element.getAttribute('data-src');
    if (url) {
      element.setAttribute('src', url);
      element.removeAttribute('data-src', url);
      element.addEventListener('load', () => {
        element.classList.add('loaded');
      });
    }
  }
}

export default new PageScrollHandler();
