import $ from 'jquery';
import 'ion-rangeslider/js/ion.rangeSlider';

$(document).ready(() => {

  const $marketValSlider = $('.js-market-val-slider');
  const $marketValText = $('.js-market-val-text');

  const $percentShareSlider = $('.js-percent-share-slider');
  const $percentShareText = $('.js-percent-share-text');

  const $depositSlider = $('.js-deposit-slider');
  const $depositeText = $('.js-deposit-text');

  const $interestRateSlider = $('.js-interest-rate-slider');
  const $interestRateText = $('.js-interest-rate-text');

  const $mortgageTermSlider = $('.js-mortgage-term-slider');
  const $mortgageTermText = $('.js-mortgage-term-text');


  const $sharePriceResultText = $('.js-share-price-result');
  const $depositResultText = $('.js-deposit-result');
  const $mortgagePaymentResultText = $('.js-mortgage-payment-result');
  const $rentPaymentResultText = $('.js-rent-payment-result');
  const $totalPaymentResultText = $('.js-total-payment-result');

  

  calculateValues();

  // MARKET VALUE SLIDER
  if ($marketValSlider.length) {

    const depositMin = $marketValText.attr('data-min-value');
    const depositMax = $marketValText.attr('data-max-value');
    const initialVal = $marketValText.val();

    $marketValSlider.ionRangeSlider({
      hide_min_max: true,
      hide_from_to: true,
      min: depositMin,
      max: depositMax,
      from: initialVal,
      onChange: updateMarketValueInput,
    });

    const instance = $marketValSlider.data("ionRangeSlider");
    $marketValText.prop('value', initialVal);

    $marketValText.on("input", () => {
      let val = parseInt($marketValText.prop("value"), 10);
      
      // validate
      if (val < depositMin) {
          val = depositMin;
      } else if (val > depositMax) {
          val = depositMax;
      }

      instance.update({
          from: val
      });
      $marketValText.prop('value', val);
    });
  }


  // PERCENT SHARE SLIDER
  if ($percentShareSlider.length) {

    const depositMin = $percentShareText.attr('data-min-value');
    const depositMax = $percentShareText.attr('data-max-value');
    const initialVal = $percentShareText.val();

    $percentShareSlider.ionRangeSlider({
      hide_min_max: true,
      hide_from_to: true,
      min: depositMin,
      max: depositMax,
      from: initialVal,
      onChange: updatePercentShareInput,
    });

    const instance = $percentShareSlider.data("ionRangeSlider");
    $percentShareText.prop('value', initialVal);

    $percentShareText.on("input", () => {
      let val = parseInt($percentShareText.prop("value"), 10);
      
      // validate
      if (val < depositMin) {
          val = depositMin;
      } else if (val > depositMax) {
          val = depositMax;
      }

      instance.update({
          from: val
      });
      $percentShareText.prop('value', val);
    });
  }


  // DEPOSIT SLIDER
  if ($depositSlider.length) {

    const depositMin = $depositeText.attr('data-min-value');
    const depositMax = $depositeText.attr('data-max-value');
    const initialVal = $depositeText.val();

    $depositSlider.ionRangeSlider({
      hide_min_max: true,
      hide_from_to: true,
      min: depositMin,
      max: depositMax,
      from: initialVal,
      onChange: updateDepositInput,
    });

    const instance = $depositSlider.data("ionRangeSlider");
    $depositeText.prop('value', initialVal);

    $depositeText.on("input", () => {
      let val = parseInt($depositeText.prop("value"), 10);
      
      // validate
      if (val < depositMin) {
          val = depositMin;
      } else if (val > depositMax) {
          val = depositMax;
      }

      instance.update({
          from: val
      });
      $depositeText.prop('value', val);
    });
  }


  // INTEREST RATE SLIDER
  if ($interestRateSlider.length) {

    const interestRateMin = $interestRateText.attr('data-min-value');
    const interestRateMax = $interestRateText.attr('data-max-value');
    const initialVal = $interestRateText.val();

    $interestRateSlider.ionRangeSlider({
      hide_min_max: true,
      hide_from_to: true,
      min: interestRateMin,
      max: interestRateMax,
      from: initialVal,
      step: 0.1,
      onChange: updateInterestRateInput,
    });

    const instance = $interestRateSlider.data("ionRangeSlider");
    $interestRateText.prop('value', initialVal);

    $interestRateText.on("input", () => {
      let val = parseFloat($interestRateText.prop("value"));
      
      // validate
      if (val < interestRateMin) {
          val = interestRateMin;
      } else if (val > interestRateMax) {
          val = interestRateMax;
      }

      instance.update({
          from: val
      });
      $interestRateText.prop('value', val);
    });
  }


  if ($mortgageTermSlider.length) {

    const loanLenMin = $mortgageTermText.attr('data-min-value');
    const loanLenMax = $mortgageTermText.attr('data-max-value');
    const initialVal = $mortgageTermText.val();

    $mortgageTermSlider.ionRangeSlider({
      hide_min_max: true,
      hide_from_to: true,
      min: loanLenMin,
      max: loanLenMax,
      from: initialVal,
      onChange: updateLoanInput,
    });

    const instance = $mortgageTermSlider.data("ionRangeSlider");
    $mortgageTermText.prop('value', initialVal);

    $mortgageTermText.on("input", () => {
      let val = parseInt($mortgageTermText.prop("value"), 10);
      
      // validate
      if (val < loanLenMin) {
          val = loanLenMin;
      } else if (val > loanLenMax) {
          val = loanLenMax;
      }

      instance.update({
          from: val
      });
      $mortgageTermText.prop('value', val);
    });
  }

  function updateMarketValueInput(data) {
    const { from } = data;
    $marketValText.prop('value', from);
    calculateValues();
  }

  function updatePercentShareInput(data) {
    const { from } = data;
    $percentShareText.prop('value', from);
    calculateValues();
  }

  function updateDepositInput(data) {
    const { from } = data;
    $depositeText.prop('value', from);
    calculateValues();
  }

  function updateInterestRateInput(data) {
    const { from } = data;
    $interestRateText.prop('value', from);
    calculateValues();
  }

  function updateLoanInput(data) {
    const { from } = data;
    $mortgageTermText.prop('value', from);
    calculateValues();
  }

  
  function calculateMortgagePayment() {
    const sharePrice = ($percentShareText.val() / 100) * $marketValText.val();
    const deposit = ($depositeText.val() / 100) * sharePrice;
    // mortgage formula -  P * ( (r * ((1+r)^n) ) /  ((1+r)^n) - 1 )
    const P = sharePrice - deposit;
    const r = $interestRateText.val() / 100 / 12;
    const n = $mortgageTermText.val() * 12;

    const mortgagePayment = P * ( (r * ((1+r) ** n)) / (((1+r) ** n) - 1) );

    return mortgagePayment;
  }

  function calculateRentPayment() {
    const share = ($percentShareText.val() / 100) * $marketValText.val();
    const clientShare = $marketValText.val() - share;
    const yearRent = clientShare * (2.75 / 100);

    const rentPayment = yearRent / 12;

    return rentPayment;
  }


  function calculateValues() {
    const sharePrice = Math.round(($percentShareText.val() / 100) * $marketValText.val());
    const deposit = Math.round(($depositeText.val() / 100) * sharePrice);
    const mortgagePayment = calculateMortgagePayment();
    const rentPayment = calculateRentPayment();
    const totalPayment = parseInt(mortgagePayment, 10) + parseInt(rentPayment, 10);

    $sharePriceResultText.text(sharePrice.toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0}));
    
    $depositResultText.text(deposit.toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }));
    
    $mortgagePaymentResultText.text(Math.round(mortgagePayment).toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }));    
    
    $rentPaymentResultText.text(Math.round(rentPayment).toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }));
    
    $totalPaymentResultText.text(totalPayment.toLocaleString('en-GB', { style: 'currency', currency: 'GBP', minimumFractionDigits: 0 }));

  }
  
});
