import $ from 'jquery';
import URI from 'urijs';

// Number of articles to load
const STARTING_COUNT = 10;
const INCREMENT_BY = 10;
let currentFaqCount = STARTING_COUNT;

const $faqListingContainer = $('.js-faq-listing');
const $faqTitle = $('.js-faq-title');
const $faqSelectedCategory = $('.js-faq-category');
const $faqApplyFilters = $('.js-apply-faq-filter');
const $faqResetFilters = $('.js-reset-faq-filter');

$(document).ready(() => {
  if ($faqListingContainer.length === 0) {
    return;
  }

  const $faqLoadMore = $('.js-faq-load-more');

  requestFaqs();

  // Load more clicked.
  $faqLoadMore.click(() => {
    currentFaqCount += INCREMENT_BY;
    updateUrl();
    requestFaqs();
  });

  // Apply filters clicked.
  $faqApplyFilters.click(() => {
    $faqApplyFilters.parents('.js-filter-dropdown').slideUp();
    updateUrl();
    requestFaqs();
  });

  // Reset filters clicked.
  $faqResetFilters.click((event) => {
    event.preventDefault();

    $faqApplyFilters.parents('.js-filter-dropdown').slideUp();
    updateUrl(true);
    requestFaqs();
  });
});

function requestFaqs() {
  const currentUri = new URI(window.location.href);

  // Build the request model from the URI query parameters.
  const requestModel = {
    // eslint-disable-next-line max-len
    topN:
      currentUri.search(true).topn === undefined ? STARTING_COUNT : currentUri.search(true).topn,
    selectedCategory: currentUri.search(true).selectedcategory,
    currentNodeId: $('.js-current-node').val(),
  };

  // Set the dropdown values based on the request model.
  $faqSelectedCategory.val(requestModel.selectedCategory).trigger('change');

  // Request the FAQs from the server.
  $.ajax({
    type: 'POST',
    url: '/umbraco/surface/faqs/getfaqslistingajax',
    data: requestModel,
  }).done((data) => {
    // Bind the response HTML to the container.
    $faqListingContainer.html(data.RenderHtml);
    $faqTitle.html(data.ResultsTitle);

    // Rebind the load more button.
    const $faqLoadMore = $('.js-faq-load-more');
    $faqLoadMore.click(() => {
      currentFaqCount += INCREMENT_BY;
      updateUrl();
      requestFaqs();
    });

    // Rebind the accordions.
    const $AccordianBtn = $('.js-toggle-accordion');
    const $Accordian = $('.js-accordion-section');
    const active = 'is-active';

    $AccordianBtn.on('click', (event) => {
      const el = $(event.currentTarget);
      event.preventDefault();
      el.toggleClass(active);
      el.siblings($Accordian).slideToggle();
    });
  });
}

function updateUrl(reset) {
  const currentUri = new URI(window.location.href);

  // Remove all URI parameters.
  currentUri.removeSearch(['selectedcategory', 'topn']);

  // If restting, don't add any URI parameters.
  if (reset === undefined || reset !== true) {
    if ($faqSelectedCategory.val() !== '') {
      currentUri.addSearch('selectedcategory', $faqSelectedCategory.val());
    }
    if (currentFaqCount !== STARTING_COUNT) {
      currentUri.addSearch('topn', currentFaqCount);
    }
  }

  // Update the URI state.
  window.history.replaceState(null, null, currentUri.toString());
}
