import whenDomReady from 'when-dom-ready';
import scrollToSmoothly from '../utils/smoothScroll';

const ACTIVE = 'active';

function initialise() {
  const sectionNav = document.querySelector('.js-section-nav--spy');

  if (!sectionNav) {
    return;
  }

  const $steps = document.querySelectorAll('.js-step-items');
  const $stepInner = document.querySelector('.js-section-nav-links');
  // ensure we start with no steps as this might be reinitialised
  $stepInner.innerHTML = '';

  const $waysToBuyBtn = document.querySelector('.js-ways-to-buy');

  const stepAmount = $steps.length;
  for (let i = 0; i < stepAmount; i += 1) {
    const item = $steps[i];
    item.classList.add(`area-${i}`);
    const closestTenureGridContainer = item.closest('.js-content-grid');
    let hiddenClass = '';
    if (closestTenureGridContainer && closestTenureGridContainer.classList.contains('hidden')) {
      // hide items in sub nav that should not be visible because the parent content is not displayed
      hiddenClass = ' hidden';
    }
    item.setAttribute('data-nav-item-id', i);
    const stepName = item.getAttribute('data-section-name');
    const svgWidth = item.getAttribute('data-svg-width');
    const svgHeight = item.getAttribute('data-svg-height');
    const svgURL = item.getAttribute('data-svg-url');
    $stepInner.innerHTML += `<a class="development-subnav__item js-section-nav-item${hiddenClass}" data-nav-item-id="${i}">
    <div class="development-subnav__item-inner">
      <svg class="icon" viewBox="0 0 ${svgWidth} ${svgHeight}"><use xlink:href="${svgURL}"></use></svg>
      ${stepName}
    </div>
  </a>`;
    if (i === stepAmount - 1) {
      addObserver();
      initSteps();
    }
  }

  function addObserver() {
    const io = new IntersectionObserver(
      (entries) => {
        Array.from(entries).forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            removeActiveState();
            const inView = Number(entry.target.getAttribute('data-nav-item-id'));
            // const navSelectChild = $stepInner.childNodes[inView + 1];
            const navSelectChild = $stepInner.childNodes[inView];
            if (navSelectChild) {
              navSelectChild.classList.add(ACTIVE);
            }
          } else {
            const inView = Number(entry.target.getAttribute('data-nav-item-id'));
            const navSelectChild = $stepInner.childNodes[inView];
            if (navSelectChild) {
              navSelectChild.classList.remove(ACTIVE);
            }
          }
        });
      },
      {
        rootMargin: '-10% 0px -10% 0px',
      },
    );

    for (let i = 0; i < stepAmount; i += 1) {
      const box = document.querySelector(`.area-${i}`);
      io.observe(box);
    }
  }

  function removeActiveState() {
    const $stepNavItems = document.querySelectorAll('.js-section-nav-item');
    const stepLength = $stepNavItems.length;
    for (let i = 0; i < stepLength; i += 1) {
      $stepNavItems[i].classList.remove(ACTIVE);
    }
  }

  function addStepAction(i) {
    const $stepNavItems = document.querySelectorAll('.js-section-nav-item');
    $stepNavItems[i].addEventListener('click', (e) => {
      if (e) {
        e.preventDefault();
      }

      const $this = e.currentTarget;

      const navItemId = $this.getAttribute('data-nav-item-id');
      const elementToScrollTo = document.querySelector(`.area-${navItemId}`);
      const elementTop = elementToScrollTo.getBoundingClientRect().top;

      const navLinks = document.querySelector('.js-section-nav-links');
      const navLinksHeight = navLinks.getBoundingClientRect().height;

      const mobileSubNav = document.querySelector('.development-subnav__select-holder');
      const mobileSubNavHeight = matchMedia('(min-width: 1200px)').matches
        ? 0
        : mobileSubNav.getBoundingClientRect().height;

      // Scroll to element position, with an offset for the nav height.
      window.scrollTo({
        behavior: 'smooth',
        top: elementTop + window.scrollY - navLinksHeight - mobileSubNavHeight,
      });
    });
  }

  function initSteps() {
    const $stepNavItems = document.querySelectorAll('.js-section-nav-item');
    const stepLength = $stepNavItems.length;

    for (let i = 0; i < stepLength; i += 1) {
      addStepAction(i);
    }
  }

  if($waysToBuyBtn != null)
  {
    $waysToBuyBtn.addEventListener('click', () => {
      scrollToSmoothly(0, 300);
    });
  }
}

whenDomReady().then(initialise);

window.reInitialiseStepNavigation = function () {
  initialise();
};
