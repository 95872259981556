import whenDomReady from 'when-dom-ready';

function initialise(){
    const contactModal = document.querySelector('.js-contact-modal');
    const contactModalBtn = document.querySelector('.js-contact-modal-btn');
    const contactModalCloseBtn = document.querySelector('.js-contact-modal-btn-close');
    const ACTIVE = 'is-active';

    contactModalBtn.addEventListener('click', () => {
        contactModal.classList.add(ACTIVE);
    });

    contactModalCloseBtn.addEventListener('click', () => {
        contactModal.classList.remove(ACTIVE);
    })
}

whenDomReady().then(initialise);