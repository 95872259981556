import whenDomReady from 'when-dom-ready';

function initialise() {
  const tabSections = document.querySelectorAll('.js-tabbed-feature-section');

  if (!tabSections) {
    return;
  }

  Array.from(tabSections).forEach((item) => {
    const section = item;
    const tabTitles = section.querySelectorAll('.js-tabbed-feature-title');

    Array.from(tabTitles).forEach((item2) => {
      const title = item2;

      title.addEventListener('click', () => {
        if (title.classList.contains('is-active')) {
          if (matchMedia('(max-width: 768px)').matches) {
            title.classList.remove('is-active');
          }
        } else {
          Array.from(tabTitles).forEach((items) => {
            const titles = items;
            titles.classList.remove('is-active');
          });

          title.classList.add('is-active');
        }
      });
    });
  });
}

whenDomReady().then(initialise);
