import $ from 'jquery';

$(document).ready(() => {
  const header = $('.js-site-header');
  const IS_SCROLLED = 'is-scrolled';


  // Scroll down and add/remove class
  $(window).on('scroll', () => {
    const num = 2;
    $(window).bind('scroll', () => {
      if ($(window).scrollTop() > num) {
        header.addClass(IS_SCROLLED);
      } else {
        header.removeClass(IS_SCROLLED);
      }
    });
  });
});
