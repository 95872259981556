import whenDomReady from 'when-dom-ready';
import objectFitImages from 'object-fit-images';

function initialise() {
  const someImages = document.querySelectorAll('.js-object-fit');
  setTimeout(() => {
    objectFitImages(someImages, { watchMQ: true });
  }, 100);
}

whenDomReady().then(initialise);

