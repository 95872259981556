import $ from 'jquery';

$(document).ready(() => {
  const $tabItem = $('.js-tab-nav-item');
  const $tabContentArea = $('.js-tab-content-area');
  const $tabContent = $('.js-tab-content');
  const ACTIVE = 'is-active';

  $tabItem.each((i, item) => {
    const el = $(item);

    el.on('click tap', (event) => {
      const eItem = $(event.currentTarget);
      const attribVal = eItem.data('tag');

      if (!eItem.hasClass(ACTIVE)) {
        $tabItem.parent().removeClass(ACTIVE);
        $tabContent.removeClass(ACTIVE);
        eItem.parent().addClass(ACTIVE);
        $tabContentArea.find(`[data-tag='${attribVal}']`).addClass(ACTIVE);
      }
    });
  });
});
