import Lottie from 'lottie-web';
import whenDomReady from 'when-dom-ready';

function initialise() {
  const container = document.querySelector('.js-home-banner-lottie');

  // eslint-disable-next-line no-unused-vars
  const animation = Lottie.loadAnimation({
    container,
    path: `/SiteFiles/src/ui/img/svg/lottie/homeBanner/${container.id}.json`,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    name: 'More More More',
  });
}

whenDomReady().then(initialise);
