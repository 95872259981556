/* eslint-disable no-console */
/* eslint-disable max-len */
import $ from 'jquery';
import AdditionalDetails from './init-additional-details';

const $formContainer = $('body .additional-details-form .form-container');
const $loginBtn = $('.js-btn-login');

class LoginButton {
  constructor(ctrl) {
    this.$control = ctrl;
    this.AdditionalDetails = new AdditionalDetails($formContainer, null, null);
  }

  init() {
    $('.js-btn-login')
      .closest('form')
      .submit((e) => {
        e.preventDefault();

        this.$control.replaceWith(`
        <a href="#" class="btn btn--primary btn--iconed btn--set-width loading">
          Logging in...
          <img class="icon" src="/SiteFiles/src/ui/img/svg/loading.svg">
        </a>`);

        const form = $(e.currentTarget);
        $.ajax({
          type: form.attr('method'),
          url: form.attr('action'),
          data: form.serialize(),
        }).done((data) => {
          if (data.redirect) {
            window.location = data.redirectUrl;
          } else if (!data.hasErrored) {
            if (data.modalForm.length > 0) {
              this.AdditionalDetails.bindForm(data);
            }
          } else {
            form.unbind('submit');
            form.parent().replaceWith(data.view);
            this.init();
          }
        });
      });
  }
}

$(document).ready(() => {
  if ($loginBtn != null) {
    $loginBtn.each((i, elem) => {
      const button = new LoginButton($(elem));
      button.init();
    });
  }
});
