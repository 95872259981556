import $ from 'jquery';

const $searchFancyBoxToggle = $('.js-site-search-toggle');
const searchModal = document.querySelector('.js-search-modal');
const $closeSearchModal = $('.js-close-search-model');
const ACTIVE = 'is-active';

$(() => {
  bindModalFormSubmit();
  bindBurgerFormSubmit();

  $searchFancyBoxToggle.click(() => {
    searchModal.classList.add(ACTIVE);
    $('body').addClass('no-scroll');
  });

  $closeSearchModal.click(() => {
    searchModal.classList.remove(ACTIVE);
    $('body').removeClass('no-scroll');
  });

  function bindModalFormSubmit() {
    // This handles form submission and replaces message container content
    $('#SiteSearchModal')
      .find('form')
      .submit((e) => {
        e.preventDefault();

        const form = $(e.currentTarget);
        $.ajax({
          type: form.attr('method'),
          url: form.attr('action'),
          data: form.serialize(),
        }).done((data) => {
          if (data.redirectUrl !== undefined && data.redirectUrl !== null) {
            window.location.href = data.redirectUrl;
          } else if (data.responseHtml !== undefined && data.responseHtml !== null) {
            form.closest('form').replaceWith(data.responseHtml);
            bindModalFormSubmit();
          }
        });
      });
  }

  function bindBurgerFormSubmit() {
    // This handles form submission and replaces message container content
    $('.js-burger-site-search')
      .find('form')
      .submit((e) => {
        e.preventDefault();

        const form = $(e.currentTarget);
        $.ajax({
          type: form.attr('method'),
          url: form.attr('action'),
          data: form.serialize(),
        }).done((data) => {
          if (data.redirectUrl !== undefined && data.redirectUrl !== null) {
            window.location.href = data.redirectUrl;
          } else if (data.responseHtml !== undefined && data.responseHtml !== null) {
            form.closest('form').replaceWith(data.responseHtml);
            bindBurgerFormSubmit();
          }
        });
      });
  }
});
