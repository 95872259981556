import PageScrollHandler from '../utils/intersection-observable';

function funcName(element) {
  element.classList.add('on-screen');
}

export function bindLazyLoads() {
  const containerAnimate = document.querySelectorAll('.js-container-animate');
  const lazyBg = document.querySelectorAll('.js-lazy-bg');
  const lazyImage = document.querySelectorAll('.js-lazy-img');
  const lazyIframe = document.querySelectorAll('.js-lazy-iframe');

  if (lazyBg !== null && lazyBg.length > 0) {
    Array.from(lazyBg).forEach((element) => {
      PageScrollHandler.registerBackgroundImageElement(element);
    });
  }
  
  if (lazyImage !== null && lazyImage.length > 0) {
    Array.from(lazyImage).forEach((element) => {
      PageScrollHandler.registerImageElement(element);
    });
  }
  
  if (lazyIframe !== null && lazyIframe.length > 0) {
    Array.from(lazyIframe).forEach((element) => {
      PageScrollHandler.registerIframeElement(element);
    });
  }
  
  if (containerAnimate !== null) {
    Array.from(containerAnimate).forEach((element) => {
      PageScrollHandler.registerNewElement(element, funcName, true);
    });
  }
}

bindLazyLoads();