/* eslint-disable no-console */
import whenDomReady from 'when-dom-ready';
import 'table-sort-js/table-sort.js';

const $showTableBtn = document.querySelectorAll('.js-property-show');
const tableDisplay = document.querySelectorAll('.js-featured-table-actual');
const ACTIVE = 'is-active';

function initialise() {
  if ($showTableBtn.length) {
    Array.from($showTableBtn).forEach((tableBtn) => {
      tableBtn.addEventListener('click', (e) => {
        e.preventDefault();
        const el = e.currentTarget;
        el.classList.toggle(ACTIVE);
        el.closest('.js-featured-result')
          .querySelector('.js-featured-table')
          .classList.toggle(ACTIVE);
      });
    });

    Array.from(tableDisplay).forEach((table) => {
      table.querySelectorAll('.js-sort').forEach((tableHead) => {
        tableHead.addEventListener('click', (f) => {
          f.preventDefault();
          const fl = f.currentTarget;
          if (fl.classList.contains(ACTIVE)) {
            fl.classList.remove(ACTIVE);
          } else {
            table.querySelectorAll('.js-sort').forEach((tHead) => tHead.classList.remove(ACTIVE));
            fl.classList.add(ACTIVE);
          }
        });
      });
    });
  }
}

whenDomReady().then(initialise);
