/* eslint-disable no-console */
import $ from 'jquery';

export default class AdditionalDetails {
  constructor(formContainer, _devDocId, _plotDocId) {
    this.$form = formContainer;
    this.redirectOnClose = $('.js-redirect').val();
    this.developmentDocId = _devDocId;
    this.plotDocId = _plotDocId;
  }

  /* eslint-disable max-len */
  bindForm(data) {
    if ($('body .additional-details-form .form-container').length) {
      $('body .additional-details-form .form-container').html(data.modalForm);
    } else {
      $('body').append(`
    <div class="additional-details-form modal-content" style="display:none">
      <div class="modal-content__content-area">
        <button type="button" class="modal-content__close-btn js-modal-close-btn" data-modal="modal-1">
          <svg class="icon" viewBox="0 0 26 26">
            <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/SiteFiles/src/ui/img/svg/cross-black.svg#cross"></use>
          </svg>
        </button>

        <div class="form-container">${data.modalForm}</div>
      </div>
    </div>`);
      this.redirectOnClose = $('.js-redirect').val();
    }

    $('.additional-details-form').css('display', 'flex').hide().fadeIn();
    // Rebind validation tool tips
    this.bindApplicationType();
    this.bindSubmitButton();

    // Show modal
    const $modalContent = $('.additional-details-form');
    $modalContent.addClass('is-active');
    $('body').addClass('no-scroll');

    // Bind modal close button
    const $modalCloseBtn = $('.additional-details-form .js-modal-close-btn');
    $modalCloseBtn.on('click', () => {
      if (this.redirectOnClose) {
        window.location = this.redirectOnClose;
      } else {
        $('.additional-details-form').fadeOut('fast', () => {
          $modalContent.removeClass('is-active');
          $('body').removeClass('no-scroll');
        });
      }
    });
  }

  bindApplicationType() {
    const $dropdownAppType = $('.js-application-type');
    const $container = $('.additional-details-form .form').parent();

    $dropdownAppType.on('change', () => {
      const requestModel = {
        ApplicationType: $dropdownAppType.val(),
        developmentDocId: this.developmentDocId,
        plotDocId: this.plotDocId,
      };

      $dropdownAppType.replaceWith(`
          Please wait...
          <img class="icon" src="/SiteFiles/src/ui/img/svg/loading.svg">`);

      $.ajax({
        type: 'POST',
        url: '/umbraco/surface/account/renderadditionaldetails',
        data: requestModel,
      }).done((data) => {
        if (data.msg != null) {
          if (data.hasErrored) {
            $container.closest('.form').replaceWith(data.view);
            $container.bindForm(data);
          } else {
            $container.replaceWith(data.msg);
          }
        }

        $container.html(data);
        this.bindSubmitButton();
      });
    });
  }

  bindSubmitButton() {
    // This handles form submission and replaces message container content
    $('.js-additional-details-submit')
      .closest('form')
      .submit((e) => {
        e.preventDefault();

        $('.js-additional-details-submit').replaceWith(`
        <a href="#" class="btn btn--primary btn--iconed btn--set-width loading">
          Please wait...
          <img class="icon" src="/SiteFiles/src/ui/img/svg/loading.svg">
        </a>`);

        const form = $(e.currentTarget);
        $.ajax({
          type: form.attr('method'),
          url: form.attr('action'),
          data: form.serialize(),
        }).done((data) => {
          if (data.redirectUrl !== undefined && data.redirectUrl !== null) {
            window.location.href = data.redirectUrl;
          } else if (data.hasErrored) {
            form.closest('.form').replaceWith(data.view);
            this.bindForm(data);
          } else {
            form.replaceWith(data.msg);
          }
        });
      });
  }
}
