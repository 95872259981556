import $ from 'jquery';

$(document).ready(() => {
  const $salesTypeSelector = $('.js-search-type-selector');
  const $boroughSelector = $('.js-search-borough-selector');
  let selectedTypeId = $('.js-search-type-selector').val();

  $salesTypeSelector.change(updateBoroughs);

  function updateBoroughs() {
    selectedTypeId = $('.js-search-type-selector').val();
    const requestModel = {
      salesType: selectedTypeId,
    };

    $.ajax({
      type: 'POST',
      url: '/umbraco/surface/development/GetApplicableBoroughs',
      data: requestModel,
    })
    .done((data) => {
      $boroughSelector.empty(); // remove old options
      $boroughSelector.append($('<option></option>').attr('value', '')
      .text('All London Locations'));
      $.each(data.boroughs, (index, borough) => {
        if (borough.Name !== 'Other') {
          $boroughSelector.append($('<option></option>').attr('value', borough.Id)
          .text(borough.Name));
        }
      });
      $.each(data.boroughs, (index, borough) => {
        if (borough.Name === 'Other') {
          $boroughSelector.append($('<option></option>').attr('value', borough.Id)
          .text(borough.Name));
        }
      });
    });
  }
});
