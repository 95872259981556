/* eslint-disable no-console */
import $ from 'jquery';

$(document).ready(() => {
  const $popupBtn = $('.js-popup-btn');
  const $popupCloseBtn = $('.js-popup-close');
  const active = 'is-active';
  const noScroll = 'no-scroll';
  const isTrue = true;
  let timer;


  $popupBtn.on('click', (event) => {
    const el = $(event.currentTarget);
    event.preventDefault();
    const dataBox = el.data('popup');
    const redirect = $(event.target).closest($popupBtn).attr('href');

    if (isTrue) {
      $(`[data-popup-box='${dataBox}']`).addClass(active);
      $('body').addClass(noScroll);
      timer = setTimeout(() => {
        window.location.href = redirect;
      }, 3000);
    }
  });

  $popupCloseBtn.on('click', (event) => {
    const el = $(event.currentTarget);
    event.preventDefault();
    const dataBox = el.data('popup');
    $(`[data-popup-box='${dataBox}']`).removeClass(active);
    $('body').removeClass(noScroll);
    clearTimeout(timer);
  });
});
