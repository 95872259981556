/* eslint-disable no-console, indent, no-tabs, max-len, no-unused-vars */
import whenDomReady from 'when-dom-ready';
import { tns } from 'tiny-slider/src/tiny-slider';

const bannerScreenCarousel = document.querySelectorAll('.js-banner-carousel');
const contentCarousel = document.querySelectorAll('.js-content-carousel');
const imageCarousel = document.querySelectorAll('.js-carousel');
const cardCarousel = document.querySelectorAll('.js-card-carousel');
const blogCardCarousel = document.querySelectorAll('.js-blog-card-carousel');
const galleryCarousel = document.querySelectorAll('.js-gallery-carousel-holder');
const devGalleryCarousel = document.querySelectorAll('.js-dev-gal-carousel');
const galleryReSaleCarousel = document.querySelectorAll('.js-gallery-carousel-resale');
const reSaleCarousel = document.querySelector('.js-resale-carousel');
const featuredDevCarousel = document.querySelectorAll('.js-featured-developments-carousel');
const statisticCarousel = document.querySelectorAll('.js-statistic-carousel');
const testimonialCarousel = document.querySelectorAll('.js-testimonial-carousel');

function initialise() {
  if (bannerScreenCarousel) {
    Array.from(bannerScreenCarousel).forEach((carousel) => {
      screenBannerCarousel(carousel);
    });
  }

  if (contentCarousel) {
    Array.from(contentCarousel).forEach((carousel) => {
      contentCarouselInit(carousel);
    });
  }

  if (imageCarousel) {
    Array.from(imageCarousel).forEach((carousel) => {
      imageCarouselInit(carousel);
    });
  }

  if (cardCarousel) {
    Array.from(cardCarousel).forEach((carousel) => {
      cardCarouselInit(carousel);
    });
  }

  if (blogCardCarousel) {
    Array.from(blogCardCarousel).forEach((carousel) => {
      blogCardCarouselInit(carousel);
    });
  }

  if (galleryCarousel) {
    Array.from(galleryCarousel).forEach((carousel) => {
      galleryCarouselInit(carousel);
    });
  }

  if (devGalleryCarousel) {
    Array.from(devGalleryCarousel).forEach((carousel) => {
      devGalleryCarouselInit(carousel);
    });
  }

  if (galleryReSaleCarousel) {
    Array.from(galleryReSaleCarousel).forEach((carousel) => {
      galleryReSaleCarouselInit(carousel);
    });
  }

  if (statisticCarousel) {
    Array.from(statisticCarousel).forEach((carousel) => {
      statisticCarouselInit(carousel);
    });
  }

  if (testimonialCarousel) {
    Array.from(testimonialCarousel).forEach((carousel) => {
      testimonialCarouselInit(carousel);
    });
  }

  if (featuredDevCarousel) {
    if (matchMedia('(max-width: 1199px)').matches) {
      Array.from(featuredDevCarousel).forEach((carousel) => {
        featuredDevCarouselInit(carousel);
      });
    }
  }

  function screenBannerCarousel(carousel) {
    tns({
      container: carousel,
      mouseDrag: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 6000,
      speed: 2000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: true,
      lazyload: true,
      nav: false,
      items: 1,
    });
  }

  function contentCarouselInit(carousel) {
    tns({
      container: carousel,
      mouseDrag: true,
      autoplay: false,
      speed: 1000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: true,
      lazyload: true,
      nav: false,
      items: 1,
    });
  }

  function imageCarouselInit(carousel) {
    tns({
      container: carousel,
      mouseDrag: true,
      autoplay: false,
      speed: 1000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: true,
      lazyload: true,
      nav: false,
      items: 1,
    });
  }

  function cardCarouselInit(carousel) {
    tns({
      container: carousel,
      mouseDrag: true,
      autoplay: false,
      speed: 1000,
      controls: true,
      controlsPosition: 'bottom',
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: false,
      lazyload: true,
      nav: false,
      items: 1,
      gutter: 30,
      responsive: {
        768: {
          items: 2,
        },
        1200: {
          items: 3,
        },
      },
    });
  }

  function blogCardCarouselInit(carousel) {
    const blogCarousel = tns({
      container: carousel,
      mouseDrag: true,
      autoplay: false,
      speed: 1000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left-white.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right-white.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: false,
      lazyload: true,
      nav: false,
      items: 1,
      gutter: 15,
      responsive: {
        768: {
          items: 2,
          gutter: 30,
        },
        1200: {
          items: 3,
        },
      },
    });

    const progressedBar = document.querySelectorAll('.js-blog-progessed-bar')[0];
    let info = blogCarousel.getInfo();
    progressedBar.style.transform = `scaleX(${info.items / info.slideCount})`;

    blogCarousel.events.on('indexChanged', () => {      
      info = blogCarousel.getInfo();
      progressedBar.style.transform = `scaleX(${(info.index + info.items) / info.slideCount})`;
    });
    
  }

  function featuredDevCarouselInit(carousel) {
    
    const fDevCarousel = tns({
      container: carousel,
      mouseDrag: true,
      autoplay: false,
      speed: 1000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left-white.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right-white.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: false,
      lazyload: true,
      nav: false,
      items: 1,
      gutter: 15,
      responsive: {
        768: {
          items: 2,
          gutter: 30,
        },
        1200: {
          items: 3,
        },
      },
    });

    const progressedBar = document.querySelectorAll('.js-feat-dev-progessed-bar')[0];
    let info = fDevCarousel.getInfo();
    progressedBar.style.transform = `scaleX(${info.items / info.slideCount})`;

    fDevCarousel.events.on('indexChanged', () => {      
      info = fDevCarousel.getInfo();
      progressedBar.style.transform = `scaleX(${(info.index + info.items) / info.slideCount})`;
    });
    
  }

  function statisticCarouselInit(carousel) {
    tns({
      container: carousel,
      mouseDrag: true,
      autoplay: true,
      autoplayButtonOutput: false,
      autoplayTimeout: 3000,
      speed: 1000,
      controls: false,
      loop: true,
      nav: false,
      items: 1,
      gutter: 30,
      responsive: {
        768: {
          items: 3,
        },
      },
    });
  }

  function testimonialCarouselInit(carousel) {
    tns({
      container: carousel,
      mouseDrag: true,
      autoplay: false,
      speed: 1000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left-white.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right-white.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: false,
      nav: false,
      items: 1,
      gutter: 30,
    });
  }

  function galleryCarouselInit(carousel) {
    const pages = carousel.getElementsByClassName('js-gallery-carousel')[0];
    const controls = carousel.getElementsByClassName('js-gallery-carousel-nav')[0];
    tns({
      container: pages,
      mouseDrag: true,
      autoplay: false,
      speed: 1000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: true,
      lazyload: true,
      nav: true,
      navContainer: controls,
      navAsThumbnails: true,
      items: 1,
    });
  }

  function devGalleryCarouselInit(carousel) {
    tns({
      container: carousel,
      mouseDrag: true,
      autoplay: false,
      speed: 1000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: false,
      lazyload: true,
      nav: false,
      items: 1,
    });
  }

  function galleryReSaleCarouselInit(carousel) {
    const resaleCal = tns({
      container: carousel,
      mouseDrag: true,
      autoplay: false,
      speed: 2000,
      controls: true,
      controlsText: [
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-left.svg#chevLeft" /></svg><span class="visuallyhidden">Previous</span>',
        '<svg class="svg-icon" viewBox="0 0 25 48"><use xlink:href="/Sitefiles/src/ui/img/svg/chev-right.svg#chevRight" /></svg><span class="visuallyhidden">Next</span>',
      ],
      loop: true,
      lazyload: true,
      nav: false,
      items: 1,
    });

    resaleCal.getInfo();

    const controls = reSaleCarousel.querySelector('.tns-controls');
    const prevBtn = controls.querySelectorAll('button')[0];
    const nextBtn = controls.querySelectorAll('button')[1];

    prevBtn.onclick = function () {
      // get slider info
      const info = resaleCal.getInfo();
      let indexCurrent = info.index - 1;
      const indexTotal = info.slideCount;

      if (indexCurrent < 1) {
        indexCurrent = indexTotal;
      }

      // update style based on index
      document.querySelector('.js-gallery-current-page').innerHTML = `${indexCurrent}`;
    };

    nextBtn.onclick = function () {
      // get slider info
      const info = resaleCal.getInfo();
      let indexCurrent = info.index + 1;
      const indexTotal = info.slideCount;

      if (indexCurrent > indexTotal) {
        indexCurrent = 1;
      }

      // update style based on index
      document.querySelector('.js-gallery-current-page').innerHTML = `${indexCurrent}`;
    };
  }
}

whenDomReady().then(initialise);
