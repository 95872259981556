/* eslint-disable no-console, indent, no-tabs, no-plusplus, new-cap, no-new, no-param-reassign, no-unused-vars */
import whenDomReady from 'when-dom-ready';
import { debounce } from 'lodash';

function initialise() {
  const searchInput = document.querySelectorAll('.js-search-input');
  const headerElem = document.querySelector('.js-header');


  let searchRes = [];

  if (searchInput) {
    Array.from(searchInput).forEach((sInput) => {
      searchInputInit(sInput);
    });
  }

  fetch('/umbraco/api/development/GetBoroughs')
    .then(resp => resp.json())
    .then(data => {
        searchRes = data;
    })
    .catch((error) => {
      console.log(error);
    });

  function fetchSearchRes(e) {
    const inp = e.target;
    const autoHolder = inp.parentElement.querySelector('.js-search-auto');
    autoHolder.innerHTML = '';
    if (inp.value !== '') {      
      const mappedResults = searchRes.map(res => {
        const check = new RegExp(inp.value, 'i');
        if(res.match(check)){
          const anchor = document.createElement('a');
          anchor.setAttribute('href', `/find-a-home/search-results/?searchTerm=${res}`);
          anchor.classList.add('suggested-link');
          const span = document.createElement('span');
          span.classList.add('suggested-link__title');            

          let resTitle = res;

          resTitle = resTitle.replace(
              new RegExp(inp.value, 'gi'),
              match => `<strong>${match}</strong>`,
          );
          span.innerHTML = `${resTitle}`;

          anchor.appendChild(span);
          autoHolder.appendChild(anchor);
          
        }
        return res;
      });
    } 
  }

  function searchInputInit(sInput) {
    const allResults = sInput.parentElement.querySelector('.js-search-complete-holder');
    const searchSubmit = sInput.parentElement.querySelector('.js-search-submit');

    sInput.addEventListener('input', debounce(fetchSearchRes, 300));

    sInput.addEventListener('focus', () => {
      allResults.style.display = 'block';
    });
  
    sInput.addEventListener('keyup', event => {
      if (event.keyCode === 13) {
        // Cancel the default action, if needed
        event.preventDefault();
  
        window.location = `/find-a-home/search-results/?searchTerm=${sInput.value}`;
      }
    });

    sInput.addEventListener('blur', () => {
      setTimeout(() => {
        allResults.style.display = 'none';
      }, 300);
    });

    searchSubmit.addEventListener('click', () => {  
        window.location = `/find-a-home/search-results/?searchTerm=${sInput.value}`;
    })

    window.addEventListener('scroll', () => {
      allResults.style.display = 'none';
      sInput.blur();
    });
  }  
}

whenDomReady().then(initialise);
