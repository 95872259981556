import whenDomReady from 'when-dom-ready';

function initialise() {
  const stickyBtn = document.querySelector('.js-sticky-register-btn');

  if (!stickyBtn) {
    return;
  }

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        stickyBtn.style.display = 'none';
      } else {
        stickyBtn.style.display = 'inline-flex';
      }
    });
  });

  observer.observe(document.querySelector('.js-register-form'));

    stickyBtn.addEventListener('click', (e) => {
      if (e) {
        e.preventDefault();
      }

      const elementToScrollTo = document.querySelector(`.js-register-form`);
      const elementTop = elementToScrollTo.getBoundingClientRect().top;

      const navLinks = document.querySelector('.js-section-nav-links');
      const navLinksHeight = navLinks.getBoundingClientRect().height;

      const mobileSubNav = document.querySelector('.development-subnav__select-holder');
      const mobileSubNavHeight = matchMedia('(min-width: 1200px)').matches
        ? 0
        : mobileSubNav.getBoundingClientRect().height;

      // Scroll to element position, with an offset for the nav height.
      window.scrollTo({
        behavior: 'smooth',
        top: elementTop + window.scrollY - (navLinksHeight + 20) - mobileSubNavHeight,
      });
    });
}

whenDomReady().then(initialise);
