import whenDomReady from 'when-dom-ready';

function initialise() {
  const formHasErrors = document.querySelector('.js-form-has-errors');
  if(formHasErrors){
    if(isIE()){
      // basic scroll (not quite right because subnav obscure top of formHasErrors element)
      formHasErrors.scrollIntoView({ block: 'start',  behavior: 'instant' });
    }
    else{
      // scroll to more correct position
      const subNav = document.querySelector('.js-section-nav-links');
      const offset = subNav ? subNav.offsetHeight  : 0;
      const scrollPosition = formHasErrors.getBoundingClientRect().top -  offset;
      window.scrollTo({top: scrollPosition, behavior: 'instant'});
    }
  }
}

function isIE() {
  // IE 10 and IE 11
  return /Trident\/|MSIE/.test(window.navigator.userAgent);
}

whenDomReady().then(initialise);
