import $ from 'jquery';

$(document).ready(() => {
  const body = $('body');
  const $header = $('.header');

  const navOverlay = document.querySelector('.js-nav-overlay');

  const $mainMenu = $('.js-main-menu-holder');
  const $menuBtn = $('.js-show-menu');
  const $microMenuBtn = $('.js-micro-menu');
  const $microMenu = $('.js-microsite-menu');

  const $subMenuBtn = $('.js-sub-nav-btn');
  const $subMenuHolder = $('.js-sub-nav-holder');

  const childNavBtn = document.querySelectorAll('.js-child-nav-btn');
  const childNav = document.querySelectorAll('.js-child-nav');
  const navItem = document.querySelectorAll('.js-nav-item');

  const iconNavItem = document.querySelectorAll('.js-icon-btn');

  const ACTIVE = 'is-active';
  const MOBMENUOPEN = 'is-open';
  const MOBMENUOPENBODY = 'menu-open';

  $menuBtn.on('click tap', (e) => {
    e.preventDefault();
    const el = $(e.currentTarget);
    el.toggleClass(ACTIVE);
    $mainMenu.toggleClass(ACTIVE);
    $header.toggleClass(MOBMENUOPEN);
    body.toggleClass(MOBMENUOPENBODY);
  });

  $microMenuBtn.on('click tap', (e) => {
    if (matchMedia('(max-width: 768px)').matches) {
      e.preventDefault();
      const el = $(e.currentTarget);
      el.toggleClass(ACTIVE);
      $microMenu.toggleClass(ACTIVE);
    }
  });

  $subMenuBtn.on('click tap', (e) => {
    e.preventDefault();
    const el = $(e.currentTarget);
    el.parent().find($subMenuHolder).slideToggle();
    if (matchMedia('(max-width: 768px)').matches) {
      el.parent().toggleClass(ACTIVE);
    }
  });

  navItem.forEach((btn) => {
    
    btn.addEventListener('mouseover', (e) => {
      const el = $(e.currentTarget);
      if (matchMedia('(min-width: 992px)').matches) {
        if(el.find('.js-child-nav') !== null) {
          navOverlay.classList.add(ACTIVE);
        }        
      }
    });

    btn.addEventListener('mouseout', () => {
      navOverlay.classList.remove(ACTIVE);
    });
  });

  iconNavItem.forEach((btn) => {    
    btn.addEventListener('mouseover', () => {
      if (matchMedia('(min-width: 992px)').matches) {
          navOverlay.classList.add(ACTIVE);
        }
    });

    btn.addEventListener('mouseout', () => {
      navOverlay.classList.remove(ACTIVE);
    });
  });

  childNavBtn.forEach((btn) => {
    btn.addEventListener('click', (e) => {
      e.preventDefault();
      const el = $(e.currentTarget);
      el.toggleClass(ACTIVE);
      el.parent().find(childNav).slideToggle();
    });
  });
});
