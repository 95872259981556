import whenDomReady from 'when-dom-ready';

function initialise(){
    const propertySearchBar = document.querySelector('.js-property-search-bar');
    const hideBarOnMobile = document.querySelector('.js-hide-property-search-mobile');
    const HIDE_MOBILE = 'hide-search-mobile';
    if(propertySearchBar && hideBarOnMobile) {
        propertySearchBar.classList.add(HIDE_MOBILE);
    }
}

whenDomReady().then(initialise);