/* eslint-disable no-console */
import $ from 'jquery';
import 'jquery.cookie';

$(document).ready(() => {
 

  // Move VersaTag to head - SD11512
  $('head').append($('script:contains("var versaTag")')[0]);

  // ADJUST THE PADDING OF THE PAGE CONTENT TO THE HEIGHT OF THE HEADER
  const $headerHolder = $('.js-site-header');
  const $header = $('.header__main');
  const $headDev = $('.header__micro-header');
  const $pageContentpadding = $('.page-content');
  const $notificationBar = $('.js-notification-bar');
  const $notificationBarClose = $('.js-notification-close-btn');

  let developmentHeaderheight = 0;
  if ($headDev.length) {
    developmentHeaderheight = $headDev.innerHeight();
  }
  let headerHeight = $header.height() + developmentHeaderheight;

  if ($headerHolder.hasClass('notification-active')) {
    headerHeight += $notificationBar.innerHeight();
  }

  if ($headerHolder.hasClass('is-transparent')) {
    headerHeight = 0;
  }

  
  
  $pageContentpadding.css({
    'padding-top': headerHeight,
  });

  function checkCookie(cookieName) {
    const cookieValue = $.cookie(cookieName);
    if (cookieValue !== 'true') {
      $headerHolder.addClass('notification-active');

      if ($headDev.length) {
        developmentHeaderheight = $headDev.innerHeight();
      }

      if ($headerHolder.hasClass('is-transparent')) {
        headerHeight = 0;
      } else {
        headerHeight = $header.height() + developmentHeaderheight + $notificationBar.innerHeight();
      }      
      
      $pageContentpadding.css({
        'padding-top': headerHeight,
      });

    }
  }

  $notificationBarClose.on('click', () => {
    $headerHolder.removeClass('notification-active');

    if ($headDev.length) {
      developmentHeaderheight = $headDev.innerHeight();
    }

    if ($headerHolder.hasClass('is-transparent')) {
      headerHeight = 0;
    } else {
      headerHeight = $header.height() + developmentHeaderheight;
    }     

    $pageContentpadding.css({
      'padding-top': headerHeight,
    });


    $.cookie('notification', 'true', {
      expires: 1,
      path: '/',
    });
  });

  checkCookie('notification');

  // Listen for orientation changes
  window.addEventListener(
    'orientationchange',
    () => {
      setTimeout(() => {
        if ($headDev.length) {
          developmentHeaderheight = $headDev.innerHeight();
        }

        headerHeight = $header.height() + developmentHeaderheight;

        if ($headerHolder.hasClass('notification-active')) {
          headerHeight += $notificationBar.innerHeight();
        }

        if ($headerHolder.hasClass('is-transparent')) {
          headerHeight = 0;
        }

        $pageContentpadding.css({
          'padding-top': headerHeight,
        });
      }, 400);
    },
    false,
  );

  $(window).resize(() => {
    if ($headDev.length) {
      developmentHeaderheight = $headDev.innerHeight();
    }

    headerHeight = $header.height() + developmentHeaderheight;

    if ($headerHolder.hasClass('notification-active')) {
      headerHeight += $notificationBar.innerHeight();
    }

    if ($headerHolder.hasClass('is-transparent')) {
      headerHeight = 0;
    }

    $pageContentpadding.css({
      'padding-top': headerHeight,
    });
  });

  $('.js-show-password').mousedown(() => {
    $('#Password').attr('type', 'text');
    $('#ConfirmPassword').attr('type', 'text');
  });
  $('.js-show-password').mouseup(() => {
    $('#Password').attr('type', 'password');
    $('#ConfirmPassword').attr('type', 'password');
  });
  $('.js-show-password').mouseleave(() => {
    $('#Password').attr('type', 'password');
    $('#ConfirmPassword').attr('type', 'password');
  });
});
