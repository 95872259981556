/* eslint-disable no-console */
import $ from "jquery";

$(document).ready(() => {
  const $socialShare = $(".js-social-button");
  const active = "is-active";

  $socialShare.on("click tap", (e) => {
    const el = $(e.currentTarget);
    el.toggleClass(active);
  });
});

$(document).ready(() => {
  const socialShares = document.querySelectorAll(".js-social-share");

  if (!socialShares) {
    return;
  }

  socialShares.forEach((socials) => {
    const shareIcon = socials.querySelector(".js-social-share-show");
    const shareIcons = socials.querySelectorAll(".js-social-share-hide");

    const shareData = {
      url: shareIcon.href,
    };

    if (hasShareSupport(shareData)) {
      shareIcons.forEach((icon) => icon.remove());
    } else {
      shareIcon.remove();
    }

    shareIcon.addEventListener("click", (event) => {
      event.preventDefault();
      share(shareData);
    });
  });
});

/**
 * Attempt to share using the Web Share API.
 * @param data The share data
 */
function share(data) {
  // Check for Level 2 support.
  if (navigator.share && navigator.canShare && navigator.canShare(data)) {
    return navigator.share(data);
  }

  // Check for Level 1 support.
  if (navigator.share) {
    return navigator.share(data);
  }

  // No support so reject.
  return Promise.reject();
}

/**
 * Check if the browser supports the Web Share API.
 * @param data The share data
 */
function hasShareSupport(data) {
  if (navigator.share && navigator.canShare && navigator.canShare(data)) {
    return true;
  }

  if (navigator.share) {
    return true;
  }

  return false;
}
