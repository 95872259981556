import $ from 'jquery';

const $calculator = $('.js-mortgage-calculator');

class MortgageCalculator {
  constructor() {
    this.$calculateButton = $('.js-calculate-repayments-button');
    this.$calculationResult = $('.js-repayments-result');
    this.$interestRateInput = $('.js-calculator-interest-rate');
    this.$loanLengthInput = $('.js-calculator-loan-length');
    this.$loanAmountInput = $('.js-calculator-loan-amount');
    this.isValid = true;
  }

  init() {
    this.$calculateButton.click(() => {
      if (this.isValid) {
        window.mortgageCalculator.calculate();
      }
    });

    // Validate loan length
    this.$loanLengthInput.change(() => {
      if ($.isNumeric(this.$loanLengthInput.val())) {
        const loanLength = parseInt(this.$loanLengthInput.val(), 10);

        if (loanLength > 35 || loanLength <= 0) {
          this.$loanLengthInput.parent().addClass('is-not-valid is-focus');

          this.isValid = false;
        } else {
          this.$loanLengthInput.parent().removeClass('is-not-valid is-focus');

          this.isValid = true;
        }
      }
    });
  }

  calculate() {
    // Build the request model from the URI query parameters.
    const requestModel = {
      interestRate: this.$interestRateInput.val(),
      lengthOfLoan: this.$loanLengthInput.val(),
      loanAmount: this.$loanAmountInput.val(),
    };

    // Perform the calculation on the server
    $.ajax({
      type: 'POST',
      url: '/umbraco/surface/development/GetMortgageCalculationAjax',
      data: requestModel,
    }).done((data) => {
      // Bind the response HTML to the calculator result area.
      this.$calculationResult.html(data);
    });
  }

  isEmptyObject(obj) {
    const returnVal = Object.keys(obj).forEach((prop) => {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        return false;
      }
      return true;
    });
    return returnVal;
  }
}

$(document).ready(() => {
  if ($calculator.length) {
    window.mortgageCalculator = new MortgageCalculator();
    window.mortgageCalculator.init();
  }
});
