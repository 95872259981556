/* eslint-disable max-len */
import $ from 'jquery';

const $savedPropertyButtons = $('.js-saved-property-btn');
const $savedProperties = $('.js-saved-properties');

class SavedPropertyButton {
  constructor(ctrl) {
    this.$control = ctrl;
    this.developmentId = ctrl.data('dev-id');
    this.plotId = ctrl.data('plot-id');
  }

  init() {
    this.$control.click(() => {
      this.addRemoveSavedProperty();
    });
  }


  addRemoveSavedProperty() {
    // Build the request model from the URI query parameters.
    const requestModel = {
      developmentId: this.developmentId,
      plotId: this.plotId,
    };


    const savingText = $savedProperties.length > 0 ? 'Removing...' : 'Saving...';

    this.$control.parent().replaceWith(`
      <a href="#" class="btn btn--primary btn--iconed btn--set-width loading">
        ${savingText}
        <img class="icon" src="/SiteFiles/src/ui/img/svg/loading.svg">
      </a>`
    );

    // Add/remove the item
    $.ajax({
      type: 'POST',
      url: '/umbraco/surface/savedproperties/addremovepropertydetails',
      data: requestModel,
    })
    .done((data) => {
      if (data.redirect) {
        window.location = '/';
      }
      // If we're on the saved properties section remove the result after clicking the button.
      if ($savedProperties.length) {
        const $resultContainer = $('.loading').parents('.js-featured-result');
        if ($resultContainer.length) {
          $resultContainer.slideUp();
          $resultContainer.next('hr').slideUp();
        }
      } else if (!data.loggedIn) {
        let plotQuery = '';

        if (data.plotId != null && parseInt(data.plotId, 10) > 0) {
          plotQuery = `&savePlot=${data.plotId}`;
        }

        $('.loading').replaceWith(`
          <a href="/support-pages/log-in/?saveDev=${data.devId}${plotQuery}" class="btn btn--primary btn--iconed btn--set-width">
            Login or register here to save
            <svg class="icon" viewBox="0 0 32 32">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/SiteFiles/src/ui/img/svg/cross.svg#cross">
              </use>
            </svg>
          </a>`
        );
      } else {
        // Otherwise, modify button to redirect to saved properties
        $('.loading').replaceWith(`
          <a href="/my-propertyfile/" class="btn btn--primary btn--iconed btn--set-width">
            Saved
            <svg class="icon" viewBox="0 0 32 32">
              <use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="/SiteFiles/src/ui/img/svg/tick.svg#tick">
              </use>
            </svg>
          </a>`
        );
        // Otherwise modify button text to "remove"
        // this.$control.attr('value', data.btnText);
      }
    });
  }
}

$(document).ready(() => {
  if ($savedPropertyButtons != null) {
    $savedPropertyButtons.each((i, elem) => {
      const button = new SavedPropertyButton($(elem));
      button.init();
    });
  }
});
