/* eslint-disable no-unused-expressions */
// Import angular
// import 'angular';
// import 'angular-animate';
// import 'angular-sanitize';
// import 'angular-ui-router';

/* eslint-disable no-console */

import 'intersection-observer';

import 'jquery';
import 'underscore';
import './components';

// import { GeneralModule } from './general/general.module';
//
// angular
//   .module('fm', [
//     'ngAnimate',
//     'ngSanitize',
//     'ui.bootstrap',
//     'ui.router',
//     GeneralModule.name,
//   ])
//   .config(configureModule)
//   .run(runModule);

// Load react only if we have components on the page.
const components = document.querySelectorAll('[data-component]');

if (components.length) {
  import(/* webpackChunkName: "app" */ './react');
}
