import $ from 'jquery';
import loadGoogleMapsApi from 'load-google-maps-api-2';
import URI from 'urijs';

if (document.getElementById('ResultsMap') !== null) {
  initialise();
}

async function initialise() {
  const googleMaps = await loadGoogleMapsApi({
    key: 'AIzaSyDy1qytJ6hg9Ud1mhFXegeTWgOajD9EFV8',
    libraries: ['places'],
    version: '3',
  });

  const { Marker, SQUARE_PIN } = await import('vendor/map-icons');

  window.resultsMap = {};

  class ResultsMap {
    constructor() {
      this.map = '';
      this.bounds = '';
      this.isInitialized = false;

      const pageUri = new URI(window.location);
      if (pageUri.search(true).viewmode === 'Map') {
        this.init();
      }

      const $listButton = $('.js-list-results');
      const $mapButton = $('.js-map-results');
      const $hdnViewMode = $('.js-listing-view-mode');
      $mapButton.click(() => {
        $hdnViewMode.val('Map');
        if (this.isInitialized === false) {
          this.init();
        }
      });

      $listButton.click(() => {
        $hdnViewMode.val('List');
      });
    }

    init() {
      this.isInitialized = true;

      this.loadMap();
      const noPoi = [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
      ];

      this.map.setOptions({ styles: noPoi });
    }

    loadMap() {
      this.mapOptions = {
        zoom: 10,
        draggable: true,
        scrollwheel: false,
        // eslint-disable-next-line max-len
        center: new googleMaps.LatLng(
          window.mapProperties.latitude,
          window.mapProperties.longitude,
        ),
        streetViewControl: true,
        mapTypeControl: true,
        mapTypeId: googleMaps.MapTypeId.ROADMAP,
        zoomControl: true,
        zoomControlOptions: {
          position: googleMaps.ControlPosition.RIGHT_BOTTOM,
        },
        clickableIcons: false,
        panControl: true,
      };

      this.map = new googleMaps.Map(document.getElementById('ResultsMap'), this.mapOptions);
      this.bounds = new googleMaps.LatLngBounds();
      this.infoWindow = new googleMaps.InfoWindow();

      if (window.developmentResults != null) {
        this.addResultsToMap(window.developmentResults);
      }
    }

    addResultsToMap(results) {
      for (let i = 0; i < results.length; i += 1) {
        const result = results[i];
        this.addMarker(result);
      }
      // fit the map to the newly inclusive bounds
      this.map.fitBounds(this.bounds);
    }

    addMarker(result) {
      const iconLabel = '<span class="map-icon fa fa-home"></span>';
      const marker = new Marker({
        map: this.map,
        position: new googleMaps.LatLng(result.latitude, result.longitude),
        icon: {
          path: SQUARE_PIN,
          fillColor: '#5C6366',
          fillOpacity: 1,
          strokeColor: '',
          strokeWeight: 0,
        },
        map_icon_label: iconLabel,
      });

      googleMaps.event.addListener(marker, 'click', () => {
        let upcomingHtml = '';
        if (result.isUpcoming === true) {
          upcomingHtml = `<div class="upcoming-overlay">
              Coming soon
              <svg class="icon" viewBox="0 0 32 32">
                <use xlink:href="/SiteFiles/src/ui/img/svg/coming-soon.svg#coming-soon"></use>
              </svg>
            </div>`;
        }
        this.infoWindow.setContent(`
        <div class="grid-container grid-container--two-third-right grid-container--no-gutter false-width">
          <div class="grid-column grid-column--no-gutter-left">
            ${upcomingHtml}
            <img style="max-width: 150px;" src="${result.imageUrl}" />
          </div>

          <div class="grid-column grid-column--no-gutter-right">
            <h3>${result.name}</h3>
            <p>${result.address}</p>
            <p>${result.summary}</p>
            <p>${result.priceFromText}</p>
            <p>${result.sharedPriceFromText}</p>
            <a href="${result.url}" target="${result.linkTarget}" class="btn btn--bordered">View</a>
          </div>
        </div>
        `);
        this.infoWindow.open(this.map, marker);
      });

      marker.setMap(this.map);
      this.bounds.extend(marker.position);
    }
  }

  $(document).ready(() => {
    window.resultsMap = new ResultsMap();
  });
}
