import $ from 'jquery';

$(document).ready(() => {
  const $qecContainer = $('.js-quick-eligibility-check');

  if ($qecContainer.length) {
    bindEvents();
  }
});

function bindEvents() {
  const $qecContainer = $('.js-quick-eligibility-check');

  $qecContainer.find('input[type=radio]').change((e) => {
    const $this = $(e.currentTarget);

    // Deselect any radio buttons on questions after the current interaction.
    const $nextRadios = $qecContainer.find('input[type=radio]')
      .slice($qecContainer.find('input[type=radio]').index($this) + 1);

    $nextRadios.prop('checked', false);
    $qecContainer.find('form').submit();
  });

  $qecContainer.find('form').submit((e) => {
    e.preventDefault();

    const form = $(e.currentTarget);
    $.ajax({
      type: form.attr('method'),
      url: form.attr('action'),
      data: form.serialize(),
    })
    .done((data) => {
      $qecContainer.replaceWith(data);
      bindEvents();
    });
  });
}
