import $ from 'jquery';

$(document).ready(() => {
  const $developmentTypeContainer = $('.js-development-type-container');
  const $salesTypeSelector = $('.js-sales-selector');
  const $developmentTypesSelector = $('.js-development-types-selector');
  const $minPriceSelector = $('.js-minprice-selector');
  const $maxPriceSelector = $('.js-maxprice-selector');
  const $pricesMinPrice = $('.js-all-minprice');
  const $pricesMaxPrice = $('.js-all-maxprice');
  const $pricesRentMinPrice = $('.js-all-rtbminprice');
  const $pricesRentMaxPrice = $('.js-all-rtbmaxprice');
  const $minPriceSelectorLabel = $('.js-minprice-label');

  updateDevelopmentTypes();
  $salesTypeSelector.change(updateDevelopmentTypes);

  function updateDevelopmentTypes() {
    if ($salesTypeSelector.val() === '1') {
      $developmentTypeContainer.removeClass('field-container--disabled');
      $developmentTypesSelector.removeAttr('disabled');
    } else {
      $developmentTypeContainer.addClass('field-container--disabled');
      $developmentTypesSelector.attr('disabled', 'disabled');
    }

    // changes price filters for rent to buy
    let $minOptions = $pricesMinPrice.find('option').clone();
    let $maxOptions = $pricesMaxPrice.find('option').clone();
    if ($salesTypeSelector.val() === '4') {
      $minOptions = $pricesRentMinPrice.find('option').clone();
      $maxOptions = $pricesRentMaxPrice.find('option').clone();
      $minPriceSelectorLabel.html('Price (£) per month');
    } else {
      $minPriceSelectorLabel.html('Price (£)');
    }
    let minIndex = $minPriceSelector.prop('selectedIndex');
    let maxIndex = $maxPriceSelector.prop('selectedIndex');
    $minPriceSelector.find('option').remove().end().append($minOptions);
    $maxPriceSelector.find('option').remove().end().append($maxOptions);
    if (minIndex >= $minOptions.length) {
      minIndex = $minOptions.length - 1;
    }
    if (maxIndex >= $maxOptions.length) {
      maxIndex = $maxOptions.length - 1;
    }
    $minPriceSelector.prop('selectedIndex', minIndex);
    $maxPriceSelector.prop('selectedIndex', maxIndex);
  }
});
