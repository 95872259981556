import GLightbox from 'glightbox';
import whenDomReady from 'when-dom-ready';

function initialise() {
  const SHOWCOUNTER = 'show-counter';
  const customLightboxHTML = `<div id="glightbox-body" class="glightbox-container">
    <div class="gloader visible"></div>
    <div class="goverlay"></div>
    <div class="gcontainer">
    <div id="glightbox-slider" class="gslider"></div>
    <button class="gnext gbtn" tabindex="0" aria-label="Next" data-customattribute="example">{nextSVG}</button>
    <button class="gprev gbtn" tabindex="1" aria-label="Previous">{prevSVG}</button>
    <button class="gclose gbtn" tabindex="2" aria-label="Close">{closeSVG}</button>
    <div class="gcounter">1/1</div>
</div>
</div>`;

  const lightbox =  GLightbox({
    selector: '.glightbox3',
    lightboxHTML: customLightboxHTML,
    zoomable: false,
    openEffect: 'none',
    closeEffect: 'none',
  });

  GLightbox({
    selector: '.glightbox2',
    zoomable: false
  });

  lightbox.on('open', () => {
    const counter = document.querySelector('.gcounter');
    counter.classList.add(SHOWCOUNTER);
    const currentslide = lightbox.getActiveSlideIndex() + 1;
    counter.innerHTML = `${currentslide} / ${lightbox.elements.length}`;
  });

  lightbox.on('slide_changed', () => {
    const counter = document.querySelector('.gcounter');
    const currentslide = lightbox.getActiveSlideIndex() + 1;
    counter.innerHTML = `${currentslide} / ${lightbox.elements.length}`;
  });
}

whenDomReady().then(initialise);