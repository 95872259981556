import $ from 'jquery';

const scrollerNav = $('.js-scroller-nav');
const scrollerNavBase = $('.js-scroller-nav-base');
const IS_FIXED = 'is-fixed';

function checkScrollerNav() {
  if ($(window).scrollTop() > scrollerNavBase.offset().top) {
    scrollerNav.addClass(IS_FIXED);
  } else {
    scrollerNav.removeClass(IS_FIXED);
  }
}

$(document).ready(() => {
  const scrollerLink = $('.js-scroller-link');
  const scrollerSection = $('.js-scroller-section');
  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();

  if (scrollerNav.length) {
    $(window).on('scroll', () => {
      $(window).bind('scroll', () => {
        checkScrollerNav();
      });
    });

    scrollerLink.on('click', (event) => {
      event.preventDefault();
      const hrefTargetRaw = $(event.currentTarget).attr('href');
      const hrefTarget = hrefTargetRaw.substring(1, hrefTargetRaw.length);
      const newTotal = $(window).scrollTop() + $(`a.js-scroller-section[name='${hrefTarget}']`)
      .offset().top;

      $('html, body').animate({
        scrollTop: newTotal - 100,
      }, 500);
    });

    $(window).scroll(() => {
      scrollerSection.each((i) => {
        const $this = $(scrollerSection[i]);
        const elementTop = $this.offset().top;
        const elementBottom = elementTop + $this.outerHeight();

        if (elementBottom > viewportTop && elementTop < viewportBottom) {
          $(scrollerLink[i]).addClass('is-active');
        } else {
          $(scrollerLink[i]).removeClass('is-active');
        }
      });
    });

    checkScrollerNav();
  }
});
