import whenDomReady from 'when-dom-ready';

const ACTIVE = 'active';

function initialise() {
  const tabContainers = document.querySelectorAll('.js-tab-container');

  Array.from(tabContainers).forEach((tabContainer) => {
    const tabs = tabContainer.querySelectorAll('.js-tab');
    if (tabs.length > 0) {
      bindTabs(tabs, tabContainer);
    }
  });
}

function bindTabs(tabs, tabContainer) {
  Array.from(tabs).forEach((tab, i) => {
    const tabContent = tabContainer.querySelectorAll('.js-tab-content')[i];
    if (tabContent) {
      tab.addEventListener('click', () => {
        handleClick(tab, tabContent, tabContainer);
      });
    }
  });
}

function handleClick(tab, tabContent, tabContainer) {
  const active = tabContainer.querySelector('.js-tab.active');
  const activeContent = tabContainer.querySelector('.js-tab-content.active');

  if (activeContent) {
    activeContent.classList.remove(ACTIVE);
  }
  tabContent.classList.add(ACTIVE);

  if (active) {
    active.classList.remove(ACTIVE);
  }
  tab.classList.add(ACTIVE);
}

whenDomReady().then(initialise);
