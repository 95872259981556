import $ from 'jquery';

$(document).ready(() => {
  if ($('#ApplicationType:visible').length) {
    $('#BoroughFields').hide();
    $('#ApplicationType').on('change', () => {
      showHideRegistrationFormControls();
    });
  }
  showHideRegistrationFormControls();

  const $homeBorough = $('.js-home-borough');
  handleHomeBoroughVisibility($homeBorough);
  $homeBorough.change((e) => {
    handleHomeBoroughVisibility(e.currentTarget);
  });

  const $workBorough = $('.js-work-borough');
  handleWorkBoroughVisibility($workBorough);
  $workBorough.change((e) => {
    handleWorkBoroughVisibility(e.currentTarget);
  });
});

function handleHomeBoroughVisibility(element) {
  const $currentTarget = $(element);

  const $homeBoroughOther = $('.js-home-borough-other');
  if ($currentTarget.val() === '-1') {
    $homeBoroughOther.show();
  } else {
    $homeBoroughOther.hide();
  }
}

function handleWorkBoroughVisibility(element) {
  const $currentTarget = $(element);

  const $workBoroughOther = $('.js-work-borough-other');
  if ($currentTarget.val() === '-1') {
    $workBoroughOther.show();
  } else {
    $workBoroughOther.hide();
  }
}

function showHideRegistrationFormControls() {
  let applicationTypeValue = $('#ApplicationType option:selected').val();
  if (!applicationTypeValue) {
    applicationTypeValue = $('#ApplicationType').val();
  }

  if (applicationTypeValue === '2' || applicationTypeValue === '556' || applicationTypeValue === '656') {
    $('#BoroughFields').show();
  } else {
    $('#BoroughFields').hide();
  }

  if (applicationTypeValue === '556') {
    $('#PrivateOwnershipFields').hide();
  } else {
    $('#PrivateOwnershipFields').show();
  }

  $('.rentToBuy').hide();
  $('.sharedOwnership').hide();

  if (applicationTypeValue === '556') {
    $('.rentToBuy').show();
  }

  if (applicationTypeValue === '2' || applicationTypeValue === '656') {
    $('.sharedOwnership').show();
  }
}
