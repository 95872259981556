import whenDomReady from 'when-dom-ready';
import debounce from 'lodash/debounce';

function initialise() {
  const ACTIVE = 'active';
  const subNav = document.querySelector('.js-subnav');
  const subNavItems = document.querySelectorAll('.js-subnav-item');

  if (!subNav) {
    return;
  }

  const navPosition = subNav.offsetTop;

  window.addEventListener('scroll', debounce(handleScroll, 100));

  function handleScroll() {
    if (window.pageYOffset >= navPosition) {
      subNav.classList.add(ACTIVE);
    } else {
      subNav.classList.remove(ACTIVE);
    }

    const scrollPos = window.pageYOffset;

    Array.from(subNavItems).forEach((item) => {
      if (item != null && item.hash !== '') {
        const refElement = document.querySelector(item.hash);
        if (refElement != null) {
          if (
            refElement.offsetTop <= scrollPos &&
            refElement.offsetTop + refElement.offsetHeight > scrollPos
          ) {
            item.classList.add(ACTIVE);
          } else {
            item.classList.remove(ACTIVE);
          }
        }
      }
    });
  }

  Array.from(subNavItems).forEach((item) => {
    item.addEventListener('click', (event) => {
      event.preventDefault();
      const target = document.querySelector(event.target.hash);
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    });
  });
}

whenDomReady().then(initialise);
